<template>
	<div>
		<modal
			:visible="show"
			@closing="handleClosing"
			@close="handleClose"
			:title="title"
			centered
			class="orderCollectionModal"
			:okButton="false"
			:cancelButton="false"
			:header-class="canceledClass"
			:footer-class="canceledClass"
		>
			<div v-if="isCanceled" class="cancelledOverlay">
				<span>Cancelled</span>
			</div>
			<b-tabs>
				<b-tab title="Transport details">
					<div class="mainContainer firstTab">
						<component-from
							style="grid-area:toFrom;"
							:editable="editable"
						/>
						<component-delivery
							style="grid-area:delivery;"
							@validStateChanged="deliveryInformationValid"
							:editable="editable"
						/>
						<component-related-orders
							style="grid-area:relatedOrders;"
							:editable="editable"
						></component-related-orders>
						<component-summary
							style="grid-area:summary;"
							@validStateChanged="summaryInformationValid"
							:editable="editable"
						></component-summary>
						<component-price
							style="grid-area:prices;"
							@validStateChanged="priceInformationValid"
							:editable="editable"
						/>
					</div>
				</b-tab>
				<b-tab title="Documents and logs"> <!-- Wotk in progress. Remove v-if when done -->
					<div class="mainContainer secondTab">
						<!-- <p
							style="grid-area:documents;"
						>Documents</p> -->
						<component-documents
							style="grid-area:documents;"
							:editable="editable"
						/>
						<component-changelog
							style="grid-area:changelog;"
						/>
					</div>
				</b-tab>
			</b-tabs>


			<b-container slot="footer" fluid>
				<div v-if="isCanceled" class="d-flex justify-content-end">
					<b-btn variant="secondary" :disabled="true">Order is canceled</b-btn>
					<b-btn slot="modal-cancel" variant="secondary" @click="modalHide">Close</b-btn>
				</div>
				<div v-else-if="isTender" class="d-flex justify-content-end">
					<b-btn variant="secondary" @click="refuseOrderCollection">Reject Transportneed</b-btn>
					<b-btn variant="primary" @click="acceptOrderCollection">Accept Transportneed</b-btn>
					<b-btn slot="modal-cancel" variant="primary" @click="modalHide">Close</b-btn>
				</div>
				<div v-else-if="editable" class="d-flex justify-content-end">
					<b-btn v-if="!dataHasChanges" slot="modal-cancel" variant="primary" @click="modalHide">Close</b-btn>
					<b-btn v-if="dataHasChanges" variant="secondary" @click="reloadSelectedOrderCollection"><font-awesome-icon style="margin-right: .75rem;" icon="undo-alt"></font-awesome-icon> Close without saving</b-btn>
					<template v-if="dataHasChanges">
						<b-btn v-if="!informationValid && validityChecked" disabled variant="danger"><font-awesome-icon style="margin-right: .75rem;" icon="times"></font-awesome-icon> Information invalid</b-btn>
						<b-btn v-else variant="primary" @click="saveDraft"><font-awesome-icon style="margin-right: .75rem;" icon="save"></font-awesome-icon> Save</b-btn>
						<b-btn v-if="!informationValid && validityChecked" disabled variant="danger"><font-awesome-icon style="margin-right: .75rem;" icon="times"></font-awesome-icon> Information invalid</b-btn>
						<b-btn v-else variant="primary" @click="saveAndCloseDraft"><font-awesome-icon style="margin-right: .75rem;" icon="save"></font-awesome-icon> Save and close</b-btn>
					</template>
					<b-btn v-if="orderCollectionReadyToFinalize" :disabled="validityChecked ? !informationValid : false" variant="success" @click="finalizeOrderCollection"><font-awesome-icon style="margin-right: .75rem;" icon="check"></font-awesome-icon> Finalize</b-btn>
					<b-btn v-if="transporterHasConfirm" :disabled="validityChecked ? !informationValid : false" variant="success" @click="sendToConfirm"><font-awesome-icon style="margin-right: .75rem;" icon="truck"></font-awesome-icon> Send to confirm</b-btn>
				</div>
				<div v-else class="d-flex justify-content-end">
					<b-btn slot="modal-cancel" variant="primary" @click="modalHide">Close</b-btn>
				</div>
			</b-container>
		</modal>
		<code-of-conduct-modal
			v-if="codeOfConductShow"
			:visible="codeOfConductShow"
			:customerCodes="[customerCode]"
			@close="codeOfConductClose"
		></code-of-conduct-modal>
		<initial-information-modal
			v-if="initialInformationShow"
			:visible="initialInformationShow"
			@close="initialInformationModalClose"
		/>
		<closing-modal
			v-if="closingModalProperties.show"
			:closeButton="closingModalProperties.closeButton"
			:okButton="closingModalProperties.okButton"
			:content="closingModalProperties.content"
			:visible="closingModalProperties.show"
			:title="closingModalProperties.title"
			:valid="closingModalProperties.valid"
			@close="closingModalClose"
		></closing-modal>
		<complete-order-modal
			v-if="completeOrderCollectionModalProperties.show"
			:closeButton="completeOrderCollectionModalProperties.closeButton"
			:okButton="completeOrderCollectionModalProperties.okButton"
			:content="completeOrderCollectionModalProperties.content"
			:visible="completeOrderCollectionModalProperties.show"
			:title="completeOrderCollectionModalProperties.title"
			@close="completeOrderCollectionModalClose"
		></complete-order-modal>
		<confirm-modal
			v-if="confirmModalProperties.show"
			:closeButton="confirmModalProperties.closeButton"
			:okButton="confirmModalProperties.okButton"
			:content="confirmModalProperties.content"
			:visible="confirmModalProperties.show"
			:title="confirmModalProperties.title"
			@close="confirmModalClose"
		></confirm-modal>
	</div>
</template>

<script>
import StackableModal from '../../StackableModal.vue';
import { mapState, mapMutations, mapActions, mapGetters } from '../store';

import ComponentFrom from './ComponentFrom.vue';
import ComponentDelivery from './ComponentDelivery.vue';
import ComponentRelatedOrders from './ComponentRelatedOrders.vue';
import ComponentSummary from './ComponentSummary.vue';
import ComponentPrice from './ComponentPrice.vue';
import ComponentChangelog from './ComponentChangelog.vue';
import ComponentDocuments from './ComponentDocuments.vue';
import CodeOfConductModal from './CodeOfConductModal.vue';
import ClosingModal from './ClosingModal.vue';
import CompleteOrderModal from './CompleteOrderModal.vue';
import InitialInformationModal from './InitialInformationModal.vue';
import ConfirmModal from '../../ConfirmModal';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
	name: 'OrderCollectionModal',
	props: ['visible'],
	components: {
		'modal': StackableModal,
		FontAwesomeIcon,
		ComponentFrom,
		ComponentDelivery,
		ComponentRelatedOrders,
		ComponentSummary,
		ComponentPrice,
		ComponentChangelog,
		ComponentDocuments,
		CodeOfConductModal,
		ClosingModal,
		CompleteOrderModal,
		InitialInformationModal,
		ConfirmModal,
	},
	data() {
		return {
			show: this.visible,
			editable: false,
			isTender: false,
			isCanceled: false,
			codeOfConductShow: false,
			initialInformationShow: false,
			validState: {
				priceInformation: true,
				deliveryInformation: true,
				summaryInformation: true,
			},
			closingModalProperties: {
				show: false,
				title: false,
				content: false,
				okButton: false,
				closeButton: false,
				valid: true
			},
			completeOrderCollectionModalProperties: {
				show: false,
				title: false,
				content: false,
				okButton: false,
				closeButton: false,
			},
			confirmModalProperties: {
				show: false,
				title: false,
				content: false,
				okButton: false,
				closeButton: false,
			}
		};
	},
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData',
			validityChecked: 'validityChecked',
			changeLog: 'selectedOrderCollectionChangelog',
		}),
		...mapGetters({
			dataHasChanges: 'getOrderCollectionHasChanges',
			orderCollectionReady: 'getOrderCollectionReadyToSend',
			orderCollectionReadyToFinalize: 'getOrderCollectionReadyToFinalize',
			transporterSettings: 'getTransporterSettings',
			isLoading: 'isLoading'
		}),
		title() {
			if(this.orderCollectionData) return `Transportneed No. ${this.orderCollectionData.referenceNo}`;
			else return '';
		},
		customerCode() {
			return this.orderCollectionData.customerSettings.code;
		},
		informationValid(){
			for(var key in this.validState){
				if(!this.validState[key]) return false;
			}
			return true;
		},
		canceledClass() {
			return this.isCanceled ? 'canceledOverlayColor' : '';
		},
		transporterHasConfirm() {
			return this.transporterSettings.hasConfirm;
		},
	},
	watch: {
		informationValid: function(val) {
			if(val){
				this.setValidityChecked(false);
			}
		}
	},
	methods: {
		...mapActions({
			reloadOrderCollection: 'reloadSelectedOrderCollection',
			save: 'saveDraft',
			finalize: 'finalizeOrderCollection',
			accept: 'acceptOrderCollection',
			refuse: 'refuseOrderCollection',
			sendConfirm: 'sendOrderCollectionToConfirm',
			flagSeenByUser: 'flagSeenByUser',
		}),
		...mapMutations([
			'setValidityChecked',
		]),
		async handleClose(){
			let lastChangelog = this.changeLog[0];
			await this.flagSeenByUser({orderCollectionId: this.orderCollectionData.id, logId: lastChangelog.id});
			this.$emit('close', this.orderCollectionData);
		},
		modalHide() {
			this.show = false;
		},
		handleClosing(abortClose) {
			if(this.dataHasChanges){
				abortClose(true);
				if(!this.informationValid){
					this.setValidityChecked(true);
				}
				this.closingModalShow();
			} else {
				abortClose(false);
			}
		},
		closingModalShow(){
			this.closingModalProperties = {
				show: true,
				title: 'Save changes?',
				content: 'Do you want to save changes before closing the order?',
				okButton: 'Save Changes',
				closeButton: 'Don\'t save',
				valid: this.informationValid
			};
		},
		initialInformationModalShow(){
			this.initialInformationShow = true;
		},
		async closingModalClose(accepted){
			this.closingModalProperties.show = false;
			if(accepted === null){
				return;
			} else if(accepted) {
				this.saveDraft();
			} else {
				await this.reloadSelectedOrderCollection();
			}
			this.modalHide();
		},
		acceptOrderCollection() {
			if(this.orderCollectionData.customerSettings.requiresCodeOfConduct){
				this.codeOfConductShow = true;
			} else {
				this.codeOfConductClose(true);
			}
		},
		async refuseOrderCollection() {
			await this.refuse(this.orderCollectionData.id);
			this.modalHide();
		},
		async codeOfConductClose(accepted) {
			this.codeOfConductShow = false;
			if(accepted) {
				await this.accept(this.orderCollectionData.id);
				this.editable = true;
				this.isTender = false;
			} else {
				this.reloadOrderCollection(this.orderCollectionData.id);
			}
			// if(accepted) {
			// 	this.initialInformationModalShow();
			// }
		},
		async initialInformationModalClose(accepted) {
			this.initialInformationShow = false;
			if(accepted) {
				await this.accept(this.orderCollectionData.id);
				this.editable = true;
				this.isTender = false;
			} else {
				this.reloadOrderCollection(this.orderCollectionData.id);
			}
		},
		async reloadSelectedOrderCollection(){
			await this.reloadOrderCollection(this.orderCollectionData.id);
			this.modalHide();
		},

		async saveDraft(){
			if(!this.informationValid){
				this.setValidityChecked(true);
				return;
			}
			await this.save();
		},
		async saveAndCloseDraft(){
			if(!this.informationValid){
				this.setValidityChecked(true);
				return;
			}
			await this.save();
			this.modalHide();
		},

		finalizeOrderCollection(){
			if(!this.informationValid){
				this.setValidityChecked(true);return;
			}
			this.completeOrderCollectionModalShow();
		},
		completeOrderCollectionModalShow(){
			this.completeOrderCollectionModalProperties = {
				show: true,
				title: 'Finalize transportneed?',
				content: 'This will mark the related orders as delivered, and save changes. Complete the transportneed?',
				okButton: 'Complete transportneed',
				closeButton: 'Don\'t complete transportneed'
			};
		},
		async completeOrderCollectionModalClose(accepted){
			this.completeOrderCollectionModalProperties.show = false;
			if(accepted){
				this.completeOrderCollection();
			} else {
				return;
			}
		},
		async completeOrderCollection(){
			await this.finalize(this.orderCollectionData.id);
			this.modalHide();
		},

		sendToConfirm() {
			if(!this.informationValid){
				this.setValidityChecked(true);return;
			}
			this.confirmModalShow();
		},
		confirmModalShow(){
			this.confirmModalProperties = {
				show: true,
				title: 'Confirm',
				okButton: 'Send to Confirm',
				closeButton: 'Cancel'
			};
		},
		async confirmModalClose({shouldSend, confirmInformation}){
			this.confirmModalProperties.show = false;
			if(shouldSend){
				this.sendConfirm({orderCollectionId: this.orderCollectionData.id, confirmInformation: confirmInformation});
			} else {
				return;
			}
		},

		priceInformationValid(val){
			this.validState.priceInformation = val;
		},
		deliveryInformationValid(val){
			this.validState.deliveryInformation = val;
		},
		summaryInformationValid(val){
			this.validState.summaryInformation = val;
		},
	},
	beforeMount() {
		this.setValidityChecked(false);
		if(this.orderCollectionData.status > 0 && this.orderCollectionData.status < 100) this.editable = true;
		if(this.orderCollectionData.status == 0) this.isTender = true;
		if(this.orderCollectionData.status == -5) this.isCanceled = true;
	}
};
</script>

<style lang="scss" scoped>
.mainContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns:100%;
}
.firstTab.mainContainer {
	padding:15px;
	grid-template-areas: 'toFrom' 'delivery' 'relatedOrders' 'summary' 'prices';
}
.secondTab.mainContainer {
	padding:15px;
	grid-template-areas:
		'documents' 'changelog';
}

.orderCollectionModal /deep/ .modal-container {
	width: 100% !important;
	margin-left: 8px;
	margin-right: 8px;
}
@media (min-width: 768px) {
	.orderCollectionModal /deep/ .modal-container {
		width: 90% !important;
		margin-left: auto;
		margin-right: auto;
	}
	.mainContainer {
		grid-template-columns: 1fr 1fr;
	}
	.firstTab.mainContainer {
		padding:15px;
		grid-template-areas:
			'toFrom delivery'
			'relatedOrders relatedOrders'
			'summary prices';
	}
	.secondTab.mainContainer {
		padding:15px;
		grid-template-areas:
			'documents changelog'
			'messages changelog';
	}
}
.orderCollectionModal /deep/ .modal-body {
	padding:0;
}
.orderCollectionModal /deep/ .modal-header {
	padding:.5rem;
	padding-bottom:.25rem;
	border-bottom: none;
}
.orderCollectionModal /deep/ .modal-header .modal-title{
	font-size:15px;
}
.orderCollectionModal /deep/ .modal-header .close:focus{
	outline: none;
}
.orderCollectionModal /deep/ .modal-footer {
	border-top: none;
	padding: .5rem 0;
}
.orderCollectionModal footer.modal-footer button {
	margin: 0 5px;
}
.orderCollectionModal footer.modal-footer button:first-child {
	margin-left: 0;
}
.orderCollectionModal footer.modal-footer button:last-child {
	margin-right: 0;
}
.orderCollectionModal .tabs /deep/ ul[role="tablist"] li {
	padding-left: 2.5px;
}
.orderCollectionModal /deep/ .cancelledOverlayColor {
	background-color: rgba(0,0,0,0.05);
}
.orderCollectionModal /deep/ .cancelledOverlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	pointer-events: none;
	background-color: rgba(0,0,0,0.05);
}
.orderCollectionModal /deep/ .cancelledOverlay > span {
	z-index: 100;
	margin: auto;
	transform: rotate(-10deg);
	font-size: 14rem;
	color: rgba(0,0,0,0.2);
}
</style>

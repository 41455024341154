<template>
	<div>
		<div
			class="summaryWrapper border"
			v-for="order in relatedOrders"
			:key="order.id"
		>
			<div
				class="summaryHeader"
				v-text="`Documents for order ${order.orderNo}, ${order.poReference}`"
			/>
			<ul class="documentList">
				<li
					v-for="document in externalDocuments(order)"
					:key="document.id"
					class="externalDocument"
					:title="document.description"
				>
					<a
						:href="document.documentUrl"
						target="_blank"
						style="margin:0 auto;"
					>
						<img :src="document.documentThumbnailUrl" style="display:block;border-bottom:1px solid gray" @load="setImageBottomMargin($event.target)">
					</a>
					<div class="documentOptions">
						<input type="text" disabled :value="document.description" size="4">
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	// mapMutations,
	// mapActions
} from '../store';

export default {
	name: 'ComponentDocuments',
	props: ['editable'],
	components: {
	},
	data() {
		return {
			alertMessage: null,
			alertCountDown: 0,
			loadingDocuments: [],
		};
	},
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData',
		}),
		relatedOrders(){
			return this.orderCollectionData.orders || [];
		},
	},
	methods: {
		externalDocuments(orderData){
			return (orderData.externalDocuments || []).slice().sort((a, b) => {
				return ('' + a.id).localeCompare(b.id);
			});
		},
		setImageBottomMargin(target){
			if(((130/target.width)*target.height) > 140) return;
			target.classList.toggle('margin');
		},
	}
};
</script>

<style  lang="scss" scoped>
.summaryHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.summaryContainer {
	margin: 1rem;
}
.documentList {
	margin: .75rem;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
	align-items: flex-end;
}
.documentList li {
	margin:.25rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
	position: relative;
}
.documentList li.dummyDocument {
	min-width: 132px;
	min-height: 185px;
	border: none;
}
.documentList li.uploadedDocument {
	position: relative;
}
.documentList img {
	max-width:130px;
}
.documentList li .documentOptions {
	position: absolute;
	width: 100%;
}
.documentList li .documentOptions input {
	padding: .125rem;
	width: 100%;
	border: none;
	border-top: 1px solid gray;
}
.documentList li .documentOptions select {
	padding: .125rem 0;
	width: 100%;
	border: none;
	border-top: 1px solid gray;
}

$background-color: #9fa8b0;
$text-color: #343a40;
.documentList li.uploadedDocument button.remove {
	position: absolute;
	padding:0 .1rem;
	margin: 3px;
	right: 0;
	border-radius: 2px;
	background-color: $background-color;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: .75;
    color: $text-color;
    border: 0;
    -webkit-appearance: none;
	cursor: pointer;
    transition: background-color 100ms, color 100ms;
}
.documentList li.uploadedDocument button.remove:hover {
	background-color: lighten($color: $background-color, $amount: 10%);
	color: lighten($color: $text-color, $amount: 10%);
}
.documentList li.uploadedDocument button.remove:focus {
	outline: none;
}

.documentList li.externalDocument img.margin {
	margin-bottom: 22px;
}
.documentList li.uploadedDocument img.margin {
	margin-bottom: 43px;
}

.alert {
	position: absolute;
    bottom: 0;
    width: calc(100% - 1rem);
	margin: .5rem;
}
.alert .close {
	line-height: .75;
}
</style>


<template>
	<div class="orderCollectionsGrid">
		<div class="toolsContainer">
			<div class="left tool" style="grid-area:leftTool;">
				<b-btn size="sm" class="b-btn" @click="acceptSelectedOrderCollections" v-if="selectedOrderCollectionsJustTender">Accept selected Transportneed</b-btn>
				<b-btn size="sm" class="b-btn" @click="refuseSelectedOrderCollections" v-if="selectedOrderCollectionsJustTender">Refuse selected Transportneed</b-btn>
				<!-- <b-btn size="sm" class="b-btn" @click="editSelectedOrderCollections" v-if="selectedOrderCollectionsJustDraft">Edit selected Transportneed</b-btn> --> <!-- WIP -->
				<b-btn size="sm" class="b-btn" @click="deleteSelectedOrderCollections" v-if="selectedOrderCollectionsJustDraftOrCanceled">Delete selected Transportneed</b-btn>
				<b-btn size="sm" class="b-btn" @click="restoreSelectedOrderCollections" v-if="selectedOrderCollectionsJustDeleted">Restore selected Transportneed</b-btn>
			</div>
			<div class="right tool" style="grid-area:rightTool;">
				<date-picker size="sm" :value="after" :invalid="afterInvalid" placeholder="From date" dateFormat="DD.MM.YYYY" @input="onDateAfterChanged($event)"></date-picker>
				<date-picker size="sm" :value="before" :invalid="beforeInvalid" placeholder="To date" dateFormat="DD.MM.YYYY" @input="onDateBeforeChanged($event)"></date-picker>
				<b-btn size="sm" class="b-btn" @click="loadOrderCollectionsByQuery">Fetch Transportneed</b-btn>
			</div>
		</div>
		<ag-grid-vue
			width="100%"
			class="ag-theme-alpine grid"
			@gridReady="onGridReady"
			:gridOptions="gridOptions"
			:columnDefs="columnDefs"
			:rowData="orderCollections"
			:rowClassRules="rowClassRules"
			:suppressColumnVirtualisation="true"
			:debounceVerticalScrollbar="true"
		/>
		<order-collection-modal
			v-if="modalShow && selectedOrderCollectionData"
			:visible="modalShow"
			@close="modalClose"
		></order-collection-modal>
		<code-of-conduct-modal
			v-if="codeOfConductShow"
			:visible="codeOfConductShow"
			:customerCodes="selectedOrderCollectionCustomers"
			@close="codeOfConductClose"
		></code-of-conduct-modal>

	</div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import moment from 'moment-timezone';
import { negotiateTranslatedText, orderStatusValues, customerLogos } from '@/core';

import { mapState, mapMutations, mapActions } from './store';
import { mapState as mapLoginState, mapActions as mapLoginActions } from '../login/store';

import OrderCollectionModal from './modals/OrderCollectionModal.vue';
import CodeOfConductModal from './modals/CodeOfConductModal.vue';

import DatePicker from './dateFilters/DatePicker.vue';
import DateFilter from './dateFilters/DateFilter.vue';
import FloatingDateFilter from './dateFilters/FloatingDateFilter.vue';

//AgGrid-buttons implemented on last column
//Must be edited directly in GridActions.vue-component
// import Actions from './GridActions.vue';

export default {
	name: 'OrderCollectionsGrid',
	components: {
		AgGridVue,
		OrderCollectionModal,
		CodeOfConductModal,
		DatePicker,
	},
	data() {
		return {
			gridOptions: {},
			components: null,
			columnDefs: [],
			rowClassRules: {},
			userColDefs: null,
			hasSavedFilters: false,
			modalShow: false,
			modalMultipleShow: false,
			codeOfConductShow: false,
			selectedOrderCollection: null,
			dateAfter: null,
			dateBefore: null,
			selectedOrderCollectionCustomers: [],
		};
	},
	computed: {
		...mapState([
			'orderCollections',
			'shortDateFormat',
			'selectedOrderCollectionData',
			'selectedOrderCollections',
			'queryStatus',
			'customsPoints',
			'defaultGridSort'
		]),
		...mapLoginState([
			'currentUser'
		]),
		after() {
			return moment(this.dateAfter, 'YYYYMMDDHHmm').format('DD.MM.YYYY');
		},
		before() {
			return moment(this.dateBefore, 'YYYYMMDDHHmm').format('DD.MM.YYYY');
		},
		afterInvalid(){
			if(this.dateAfter !== null){
				let long = 'YYYYMMDDHHmm';
				let before = moment(this.dateBefore, 'YYYYMMDDHHmm').isValid();
				let after = moment(this.dateAfter, long);
				if(
					!(before ? after.isSameOrBefore(moment(this.dateBefore, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		beforeInvalid(){
			if(this.dateBefore !== null){
				let long = 'YYYYMMDDHHmm';
				let after = moment(this.dateAfter, 'YYYYMMDDHHmm').isValid();
				let before = moment(this.dateBefore, long);
				if(
					!(after ? before.isSameOrAfter(moment(this.dateAfter, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		selectedOrderCollectionsJustTender(){
			if(!this.selectedOrderCollections.length) return false;
			for(let orderCollection of this.selectedOrderCollections){
				if(orderCollection.status != 0) return false;
			}
			return true;
		},
		selectedOrderCollectionsJustDraft(){
			if(!this.selectedOrderCollections.length) return false;
			for(let orderCollection of this.selectedOrderCollections){
				if(orderCollection.status <= 0 || orderCollection.status >= 100) return false;
			}
			return true;
		},
		selectedOrderCollectionsJustDraftOrCanceled(){
			if(!this.selectedOrderCollections.length) return false;
			for(let orderCollection of this.selectedOrderCollections){
				if((orderCollection.status < 10 || orderCollection.status >= 100) && orderCollection.status !== -5) return false;
			}
			return true;
		},
		selectedOrdersJustDraftOrCancelled(){
			if(!this.selectedOrders.length) return false;
			for(let order of this.selectedOrders){
				if((order.status < 10 || order.status >= 100) && order.status !== -5) return false;
			}
			return true;
		},
		selectedOrderCollectionsJustDeleted(){
			if(!this.selectedOrderCollections.length) return false;
			for(let orderCollection of this.selectedOrderCollections){
				if(orderCollection.status >= -5) return false;
			}
			return true;

		},
	},
	methods: {
		t(key) {
			return negotiateTranslatedText('', key);
		},
		...mapMutations([
			'setSelectedOrderCollection',
			'setSelectedOrderCollections',
			'clearSelectedOrderCollection',
			'setStoredGridOptions',
		]),
		...mapActions([
			'reloadSelectedOrderCollection',
			'getOrderCollectionsByQuery',
			'sendOrderCollectionToTMS',
			'deleteOrderCollection',
			'restoreOrderCollection',
			'acceptOrderCollection',
			'refuseOrderCollection',
			'flagSeenByUser',
		]),
		...mapLoginActions([
			'loadUserDefinedColumnDefinitions',
		]),
		onGridReady() {
			// this.gridOptions.api.setSortModel(this.defaultGridSort); // Stored in state
		},
		async onRowSelected(e){
			let selectedOrderCollection = this.findOrderCollectionById(e.data.id);
			this.setSelectedOrderCollection(selectedOrderCollection);
			this.modalShow = !this.modalShow;
			if(!selectedOrderCollection || !selectedOrderCollection.hasChanges){
				// await this.flagSeenByUser(e.data.id); // Moved to modal on close
				await this.reloadSelectedOrderCollection(e.data.id);
			}
		},
		onRowMultipleSelectionChanged(e) {
			let orderCollections = [];
			for(let orderCollection of e.api.getSelectedNodes()){
				orderCollections.push(this.findOrderCollectionById(orderCollection.data.id));
			}
			this.setSelectedOrderCollections(orderCollections);
		},
		deleteSelectedOrderCollections() {
			if(!this.selectedOrderCollections.length){
				return;
			} else if(this.selectedOrderCollections.length > 1){
				for(let orderCollectionId of this.selectedOrderCollections.map(x => x.id)) {
					this.deleteOrderCollection(orderCollectionId);
				}
			} else {
				this.deleteOrderCollection(this.selectedOrderCollections[0].id);
			}
			this.setSelectedOrderCollections([]);
		},
		async codeOfConductClose(accepted){
			this.codeOfConductShow = false;
			if(accepted){
				if(!this.selectedOrderCollections.length){
					return;
				} else if(this.selectedOrderCollections.length > 1){
					for(let orderCollectionId of this.selectedOrderCollections.map(x => x.id)) {
						this.acceptOrderCollection(orderCollectionId);
					}
				} else {
					this.acceptOrderCollection(this.selectedOrderCollections[0].id);
				}
			}
			this.selectedOrderCollectionCustomers = [];
		},
		acceptSelectedOrderCollections(){
			let requiresCodeOfConduct = this.selectedOrderCollections.some(x => !!x.customerSettings.requiresCodeOfConduct);
			if(requiresCodeOfConduct){
				this.selectedOrderCollectionCustomers = Array.from(new Set(this.selectedOrderCollections.map(x => x.customerSettings.code)));
				this.codeOfConductShow = true;
			} else {
				this.codeOfConductClose(true);
			}
		},
		refuseSelectedOrderCollections() {
			if(!this.selectedOrderCollections.length){
				return;
			} else if(this.selectedOrderCollections.length > 1){
				for(let orderCollectionId of this.selectedOrderCollections.map(x => x.id)) {
					this.refuseOrderCollection(orderCollectionId);
				}
			} else {
				this.refuseOrderCollection(this.selectedOrderCollections[0].id);
			}
			this.setSelectedOrderCollections([]);
		},
		restoreSelectedOrderCollections() {
			if(!this.selectedOrderCollections.length){
				return;
			} else if(this.selectedOrderCollections.length > 1){
				for(let orderCollectionId of this.selectedOrderCollections.map(x => x.id)) {
					this.restoreOrderCollection(orderCollectionId);
				}
			} else {
				this.restoreOrderCollection(this.selectedOrderCollections[0].id);
			}
			this.setSelectedOrderCollections([]);
		},
		findOrderCollectionById(id){
			for(let orderCollection of this.orderCollections) {
				if(orderCollection.id === id){
					return orderCollection;
				}
			}
			return null;
		},
		modalClose(orderCollection) {
			this.modalShow = false;
			this.clearSelectedOrderCollection();
			if(this.orderCollections.filter(o => o.id === orderCollection.id).length){
				this.gridOptions.api.applyTransaction({update:[orderCollection]});
				this.gridOptions.api.redrawRows();
			}
		},
		modalMultipleClose(orderCollections) {
			this.modalMultipleShow = false;
			// this.gridOptions.api.deselectAll(); // Deselect after close or not?
			this.gridOptions.api.applyTransaction({update:orderCollections});
			this.gridOptions.api.redrawRows();
		},
		onDateAfterChanged(value){
			let dateValid = moment(value, 'DD.MM.YYYY').isValid();
			this.dateAfter = dateValid ? moment(value, 'DD.MM.YYYY').format('YYYYMMDD0000') : null;
		},
		onDateBeforeChanged(value){
			let dateValid = moment(value, 'DD.MM.YYYY').isValid();
			this.dateBefore = dateValid ? moment(value, 'DD.MM.YYYY').format('YYYYMMDD2359') : null;
		},
		loadOrderCollectionsByQuery(){
			if(this.afterInvalid || this.beforeInvalid) return;
			let params = {};
			if(this.dateAfter !== null){
				params.etd_ge = this.dateAfter;
			}
			if(this.dateBefore !== null) {
				params.etd_le = this.dateBefore;
			}
			//Which status orderCollections should be searched
			//Changed each time different type is loaded, i.e active (0 < 100), completed, or deleted
			if(!this.queryStatus){
				params.status_ge = -5;
				params.status_lt = 100;
			} else {
				params.status_eq = this.queryStatus;
			}
			this.getOrderCollectionsByQuery(params);
		},
		orderQuantity(relatedOrders){
			return relatedOrders.map(x => x.freightLinesQuantity).reduce((sum, elem) => sum + elem, 0);
		},
		orderWeight(relatedOrders){
			return relatedOrders.map(x => x.freightLinesGrossWeight).reduce((sum, elem) => sum + elem, 0).toFixed(2);
		},
		orderPriceItems(relatedOrders){
			return relatedOrders.reduce((a, o) => a.concat(o.transporter.priceItems), []);
		}
	},
	beforeMount() {
		this.gridOptions = {
			enableCellChangeFlash: true,
			domLayout: 'normal',
			suppressPropertyNamesCheck: true,
			suppressColumnMoveAnimation: true,
			pagination: false,
			context: {
				componentParent: this
			},
			onRowDoubleClicked: this.onRowSelected,
			suppressRowClickSelection: true,
			rowSelection: 'multiple',
			onSelectionChanged: this.onRowMultipleSelectionChanged,
			refreshCells: true,
			getRowNodeId: (data) => {return data.id;},
			dateComponentParams: {dateFormat: 'YYYYMMDDHHmm'},
			rowHeight: 48,
			valueCache: true,
			defaultColDef: {
				editable: false,
				headerClass: 'table-header',
				resizable: true,
				floatingFilter: true,
				filterParams: {
					buttons: ['clear'],
				},
				sortable: true,
				filter: true,
			},
		};
		this.columnDefs = [
			{
				field: 'customerLogo',
				headerName: '',
				width: 100,
				resizable: false,
				valueGetter: (params) => {
					if(!params.data.customerSettings) return;
					return params.data.customerSettings.name;
				},
				cellRenderer: (params) => {
					if(!params.data.customerSettings) return;
					var customerCode = params.data.customerSettings.code;
					if(customerCode) {
						let src = customerLogos[customerCode];
						return `<img data-name="logo" class="orderCollection-customer-logo" src="${src}">`;
					}
				},
			},
			{
				field: 'referenceNo',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				width: 150,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.referenceNo),
					'red': 'true',
				},
				valueGetter: (params) => {
					return parseInt(params.data.referenceNo);
				},
				cellRenderer: (params) => {
					return `${params.data.referenceNo}`;
				},
				comparator: (a,b) => +a - +b
			},
			{
				field: 'status',
				width: 150,
				sort: 'asc',
				sortIndex: 0,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.status),
					'green': (params) => params.data.status === 10 || params.data.status === 30
				},
				valueGetter: (params) => {
					return this.t(`orderStatus/${orderStatusValues(params.data.status)}`);
				},
				cellRenderer: (params) => {
					return this.t(`orderStatus/${orderStatusValues(params.data.status)}`);
				},
				comparator: (a,b, na, nb) => +na.data.status - +nb.data.status
			},
			{
				field: 'to',
				headerName: 'To',
				width: 350,
				cellClassRules: {
					'unseenChanges': ({data: {changeMap}}) => Boolean(changeMap.to),
					'yellow': 'true',
					'left': 'true'
				},
				cellRenderer: (params) => {
					let party = params.data.to;
					if(!party) return;
					return addressRender(party);
				},
				valueGetter(params) {
					let party = params.data.to;
					if(!party) return;
					return `${party.name} ${party.address1} ${party.postalPlace}`;
				},
			},
			// AWB
			{
				field: 'numberOfPackages',
				cellClass: 'right',
				headerName: 'Packages',
				width: 150,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.numberOfPackages),
					'red': 'true',
					'right': 'true'
				},
				valueGetter: (params) => {
					return params.data.numberOfPackages;
				},
				cellRenderer: (params) => {
					return `${ this.orderQuantity(params.data.orders) } / ${params.data.numberOfPackages}`;
				},
			},
			// {
			// 	field: 'iataCode',
			// 	headerName: 'Airport destination',
			// 	width: 150,
			// 	cellClassRules: {
			// 		'unseenChanges': ({data:{changeMap}}) => Boolean((changeMap.to || {}).iataCode),
			// 		'yellow': 'true',
			// 		'left': 'true'
			// 	},
			// 	valueGetter: (params) => {
			// 		let party = params.data.to || {};
			// 		return party.iataCode;
			// 	}
			// },
			// // Flight details
			// {
			// 	field: 'flightDetails',
			// 	headerName: 'Flight details',
			// 	cellClassRules: {
			// 		'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.departingFlight || changeMap.arrivingFlight),
			// 	},
			// 	valueGetter: (params) => {
			// 		return `${params.data.departingFlight || ''}${params.data.arrivingFlight || ''}`;
			// 	},
			// 	cellRenderer: (params) => {
			// 		return `
			// 			<div>${params.data.departingFlight || ''}</div>
			// 			<div>${params.data.arrivingFlight || ''}</div>
			// 		`;
			// 	},
			// },
			// ArrivalDay
			{
				field: 'etaDay',
				headerName: 'Arrival day',
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.eta),
					'red': 'true'
				},
				valueGetter: (params) => {
					if(params.data.eta == null) return null;
					return moment(params.data.eta.date, 'YYYYMMDDHHmm').isoWeekday();
				},
				cellRenderer: (params) => {
					if(params.data.eta == null) return null;
					return moment(params.data.eta.date, 'YYYYMMDDHHmm').format('dddd');
				},
			},
			// TimeOfArrival
			{
				field: 'etaTime',
				headerName: 'Time of arrival',
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.eta),
					'red': 'true'
				},
				valueGetter: (params) => {
					if(params.data.eta == null) return null;
					return moment(params.data.eta.date, 'YYYYMMDDHHmm').format('HH:mm');
				},
				cellRenderer: (params) => {
					if(params.data.eta == null) return null;
					return moment(params.data.eta.date, 'YYYYMMDDHHmm').format('HH:mm');
				},
			},
			// Terminal
			{
				field: 'departingTerminal',
				headerName: 'Terminal',
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.departingTerminal),
					'red': 'true'
				},
				valueGetter: (params) => {
					return params.data.departingTerminal;
				},
				cellRenderer: (params) => {
					return params.data.departingTerminal;
				},
			},
			// DeadlineTerminal
			{
				field: 'etdTime',
				headerName: 'Deadline terminal',
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.etd),
					'red': 'true'
				},
				valueGetter: (params) => {
					if(params.data.etd == null) return null;
					return moment(params.data.etd.date, 'YYYYMMDDHHmm').format('HH:mm');
				},
				cellRenderer: (params) => {
					if(params.data.etd == null) return null;
					return moment(params.data.etd.date, 'YYYYMMDDHHmm').format('HH:mm');
				},
			},
			// TerminalInfo
			{
				field: 'terminalInformation',
				headerName: 'Terminal information',
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.terminalInformation),
					'yellow': 'true'
				},
				valueGetter: (params) => {
					return params.data.terminalInformation;
				},
				cellRenderer: (params) => {
					return params.data.terminalInformation;
				},
			},
			// Rate
			{
				field: 'rate',
				headerName: 'Rate',
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.estimatedPriceItems),
					'yellow': 'true'
				},
				valueGetter: (params) => {
					return priceRate(this.orderPriceItems(params.data.orders), this.orderWeight(params.data.orders));
				},
				cellRenderer: (params) => {
					return priceRateRenderer(this.orderPriceItems(params.data.orders), this.orderWeight(params.data.orders));
				},
			},
			{
				field: 'from',
				headerName: 'From',
				width: 350,
				sort: 'asc',
				sortIndex: 2,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.from),
					'left': 'true'
				},
				cellRenderer: (params) => {
					let party = params.data.from;
					if(!party) return;
					return addressRender(party);
				},
				valueGetter(params) {
					let party = params.data.from;
					if(!party) return;
					return `${party.name} ${party.address1} ${party.postalPlace}`;
				},
			},
			{
				field: 'grossWeight',
				cellClass: 'right',
				headerName: 'Gross Weight',
				width: 175,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.grossWeight),
					'red': 'true',
					'right': 'true'
				},
				valueGetter: (params) => {
					return params.data.grossWeight ? params.data.grossWeight.toFixed(2) : undefined;
				},
				cellRenderer: (params) => {
					return `${ this.orderWeight(params.data.orders) } / ${params.data.grossWeight ? params.data.grossWeight.toFixed(2) : undefined} kg`;
				},
			},
			{
				field: 'incotermsCode',
				headerName: 'Incoterms',
				width: 150,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.incotermsCode || changeMap.incotermsPlace),
					'left': 'true'
				},
				valueGetter: (params) => {
					if(!params.data.incotermsCode || !params.data.incotermsPlace) return null;
					return `${params.data.incotermsCode}${params.data.incotermsPlace}`;
				},
				cellRenderer: (params) => {
					if(!params.data.incotermsCode || !params.data.incotermsPlace) return null;
					return `${params.data.incotermsCode} - ${params.data.incotermsPlace}`;
				},
			},
			{
				field: 'customsCrossingPoint',
				headerName: 'Border Crossing',
				width: 200,
				editable: false,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.customsCrossingPoint),
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.customsCrossingPoint;
				},
				cellRenderer: (params) => {
					if(params.data.customsCrossingPoint){
						let ccp = this.customsPoints.find(x => x.tollst == params.data.customsCrossingPoint);
						return ccp ? ccp.tollsted : null;
					}
					return null;
				}
			},
			{
				field: 'vehicleLicensePlate',
				headerName: 'Vehicle No.',
				width: 200,
				editable: false,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.vehicleLicensePlate),
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.vehicleLicensePlate;
				},
				cellRenderer: (params) => {
					return params.data.vehicleLicensePlate;
				}
			},
			{
				field: 'vehicleDriverName',
				headerName: 'Vehicle Driver',
				width: 200,
				editable: false,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.vehicleDriverName),
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.vehicleDriverName;
				},
				cellRenderer: (params) => {
					return params.data.vehicleDriverName;
				}
			},
			{
				field: 'comment',
				headerName: 'Comment',
				width: 200,
				editable: false,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.comment),
					'left': 'true'
				},
				valueGetter: (params) => {
					return params.data.comment;
				},
				cellRenderer: (params) => {
					return params.data.comment;
				}
			},
			{
				field: 'etd',
				sort: 'asc',
				sortIndex: 1,
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.etd),
					'red': 'true',
					'left': 'true'
				},
				valueGetter: (params) => {
					var etd = params.data.etd;
					if(etd == null) return null;
					return moment(etd.date, 'YYYYMMDDHHmm');
				},
				cellRenderer: (params) => {
					var etd = params.data.etd;
					if(params.data.etd == null) return null;
					return dateRender(etd, 'DD.MM.YY');
				},
				width: 171,
				comparator: dateCompare,
				filterFramework: DateFilter,
				filterParams: {
					dateFormat: 'DD.MM.YY',
					clearButton: true
				},
				floatingFilterComponentFramework: FloatingDateFilter,
				floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			},
			{
				field: 'eta',
				cellClassRules: {
					'unseenChanges': ({data:{changeMap}}) => Boolean(changeMap.eta),
					'red': 'true',
					'left': 'true'
				},
				valueGetter: (params) => {
					var eta = params.data.eta;
					if(eta == null) return null;
					return moment(eta.date, 'YYYYMMDDHHmm');
				},
				cellRenderer: (params) => {
					var eta = params.data.eta;
					if(params.data.eta == null) return null;
					return dateRender(eta, 'DD.MM.YY');
				},
				width: 171,
				comparator: dateCompare,
				filterFramework: DateFilter,
				filterParams: {
					dateFormat: 'DD.MM.YY',
					clearButton: true
				},
				floatingFilterComponentFramework: FloatingDateFilter,
				floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			},
			{
				field: 'priceItems',
				cellClass: 'left',
				headerName: 'Price',
				width: 150,
				valueGetter: (params) => {
					return priceItemsSum(params.data.orders);
				},
				cellRenderer: (params) => {
					return priceRenderer(params.data.orders);
				}
			},
			// {
			// 	field: 'orders',
			// 	cellClass: 'left',
			// 	headerName: 'Orders',
			// 	width: 150,
			// 	valueGetter: (params) => {
			// 		return params.data.orders;
			// 	},
			// 	cellRenderer: (params) => {
			// 		return params.data.orders.length;
			// 	}
			// },
			// {
			// 	field: 'comment',
			// 	cellClass: 'left',
			// 	headerName: 'Comment',
			// 	width: 200,
			// 	valueGetter: (params) => {
			// 		return params.data.comment;
			// 	},
			// 	cellRenderer: (params) => {
			// 		return params.data.comment || '';
			// 	}
			// },
			{
				field: 'id', pinned: 'left',
				width: 34,
				cellClass: 'left',
				headerName: '',
				editable: false, filter: false,
				resizable: false, sortable: false,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true
			},
		];
		this.rowClassRules = {
			// 'unseenChanges': (params) => {
			// 	let {seenBy = []} = params.data;
			// 	return !seenBy.includes(this.currentUser.uid);
			// }
			'canceled': (params) => {
				return params.data.status === -5;
			}
		};
	},
	async mounted() {
		var orderCollectionId = this.$route.params.orderCollectionId;
		if(orderCollectionId){
			this.$router.replace('/orderCollections');
			let eventMock = {data:{id:orderCollectionId}}; //Hack into onRowSelected
			await this.onRowSelected(eventMock);
		}
		// Sort model must be set after gridOptions are defined
		// this.gridOptions.api.setSortModel(this.defaultGridSort); // Stored in state

		this.setStoredGridOptions(this.gridOptions);
		this.loadUserDefinedColumnDefinitions();
	}
};

function addressRender(party) {
	if(!party) return;
	var firstLine = party.name || '';
	var secondLine = [party.address1, party.postalPlace].filter(x => !!x);
	secondLine = secondLine.join(', ');
	return `
		<div class="firstAddressLine">${firstLine}</div>
		<div class="secondAddressLine">${secondLine}</div>
	`;
}
function dateRender(dateObject, dateFormat) {
	var date = moment(dateObject.date, 'YYYYMMDDHHmm');
	var value = date.format(dateFormat);
	if (dateObject.hasTime) value += date.format(' HH:mm');
	if (!date.isValid()) return null;
	return `
        <div class="dateValue">${value}</div>
    `;
}
// function dateRender(dateObject, dateFormat) {
// 	var date = moment(dateObject.date, 'YYYYMMDDHHmm');
// 	//date.tz(dateObject.timeZone);
// 	var value = date.format(dateFormat);
// 	if (dateObject.hasTime) value += date.format(' HH:mm');
// 	if (!date.isValid()) return null;
// 	let weekday =  moment(dateObject.date, 'YYYYMMDDHHmm').format('ddd');
// 	let dow = moment(dateObject.date, 'YYYYMMDDHHmm').isoWeekday() - 1;
// 	return `
//         <div class="dateValue">${value}</div>
//         <div class="dateDay" style="--dow:${dow};">${weekday}</div>
//     `;
// }
function dateCompare(a, b) {
	return a.valueOf() - b.valueOf();
}
function priceRateRenderer(items = [], grossWeight){
	if(!items[0]) return (0.00).toFixed(2);
	var sameCurrency = true;
	for(let item of items) {
		if(items.some(i => (i !== item) && (i.currency !== item.currency))){
			sameCurrency = false;
			break;
		}
	}
	if(!sameCurrency) {
		return 'Unable to calculate.\nDifferent currencies.';
	}
	return `${items[0].currency} ${priceRate(items, grossWeight)}`;
}
function priceRate(items = [], grossWeight){
	if(!items[0]) return (0.00).toFixed(2);
	let prices = items.filter(i => i.type === 'frakt');
	let sum = prices.reduce( (sum, item) => {
		return sum + (item.grossAmount || 0);
	}, 0);
	let rate = (sum / grossWeight).toFixed(2);
	return rate;
}
function priceRenderer(orders){
	let items = orders.reduce((p,o) => p.concat(o.transporter.priceItems), []);
	if(!items || !items[0]) return 0;
	var sameCurrency = true;
	for(let item of items) {
		if(items.some(i => (i !== item) && (i.currency !== item.currency))){
			sameCurrency = false;
			break;
		}
	}
	if(!sameCurrency) {
		return 'Unable to calculate.\nDifferent currencies.';
	}
	return `${items[0].currency} ${priceItemsSum(null, items)}`;
}
function priceItemsSum(orders, items = null){
	items = items || orders.reduce((p,o) => p.concat(o.transporter.priceItems), []);
	if(!items || !items[0]) return 0;
	return items.reduce((t, i) => {
		return i.netAmount + t;
	}, 0).toFixed(2);
}
</script>

<style lang="scss" scoped>
.orderCollectionsGrid {
    display: grid;
    grid-template-rows: 134px auto 0px;
    grid-template-areas: 'tools' 'grid';
}
.orderCollectionsGrid .grid {
	grid-area: 'grid';
}
.orderCollectionsGrid .grid /deep/ .firstAddressLine {
    font-size: 1em;
}
.orderCollectionsGrid .grid /deep/ .secondAddressLine {
    font-size: 0.75em;
}
.orderCollectionsGrid .grid /deep/ .dateValue {
    font-size: 1em;
}
.orderCollectionsGrid .grid /deep/ .dateDay {
    font-size: 0.75em;
	padding-left: calc(15.5px * var(--dow));
}
.orderCollectionsGrid .grid /deep/ .ag-row.unseenChanges {
	background-color: lighten(#ffc107, 35%);
}
.orderCollectionsGrid .grid /deep/ .ag-row.canceled {
	background-color: #b8a9a97f;
}
.orderCollectionsGrid .grid /deep/ .ag-cell.unseenChanges {
	background-color: lighten(#ffc107, 35%);
}
.orderCollectionsGrid .grid /deep/ .ag-cell.unseenChanges.green {
	background-color: lighten(#0fff07, 35%);
}
.orderCollectionsGrid .grid /deep/ .ag-cell.unseenChanges.red {
	background-color: lighten(#ff0707, 35%);
}
.orderCollectionsGrid .grid /deep/ .ag-cell.unseenChanges.yellow {
	background-color: lighten(#ffc107, 10%);
}
.orderCollectionsGrid .grid /deep/ .ag-cell,
.orderCollectionsGrid .grid /deep/ .ag-header-cell,
.orderCollectionsGrid .grid /deep/ .ag-pinned-left-cols-container .ag-cell,
.orderCollectionsGrid .grid /deep/ .ag-pinned-left-header .ag-header-cell {
	border-right: 1px solid #e6e6e6 !important;
}
.orderCollectionsGrid .grid /deep/ .ag-cell.ag-cell-focus,
.orderCollectionsGrid .grid /deep/ .ag-pinned-left-cols-container .ag-cell.ag-cell-focus {
    border: 1px solid darkgrey !important;
}
.orderCollectionsGrid .grid /deep/ .ag-cell.left {
    text-align: left;
}
.orderCollectionsGrid .grid /deep/ .ag-cell.right {
    text-align: right;
}
.orderCollectionsGrid .grid /deep/ .ag-cell.mono {
	font-family: 'Roboto Mono';
}
.orderCollectionsGrid .grid /deep/ .ag-root {
	border-top: 1px solid #e6e6e6 !important;
}
.orderCollectionsGrid .grid /deep/ .orderCollection-customer-logo {
    max-width: 4rem;
    max-height: 2rem;
    width: auto;
    height: auto;
}
.toolsContainer .tool {
	margin: 7px 0;
	display: flex;
	justify-content: center;
}
.b-btn {
	margin: 0 5px;
}
.toolsContainer {
	grid-area: 'tools';
	display: grid;
	padding:15px;
	grid-template-columns:1fr;
	grid-template-rows:1fr 1fr;
	grid-template-areas:
		'leftTool' 'rightTool';
}
@media (min-width: 425px) {
	.orderCollectionsGrid {
		grid-template-rows: 102px auto 0px;
	}
}
@media(min-width: 768px){
	.toolsContainer .tool.left {
		justify-content: flex-start;
	}
	.toolsContainer .tool.right {
		justify-content: flex-end;
	}
	.toolsContainer {
		grid-gap: 10px;
		grid-template-columns:1fr 1fr;
		grid-template-rows:1fr;
		grid-template-areas:
			'leftTool rightTool';
	}
	.orderCollectionsGrid {
		grid-template-rows: 66px auto 0px;
	}
}
</style>

import { getOrderCollections, getDeletedOrderCollections, getCompletedOrderCollections, getOrderCollectionById, getOrderCollectionsByQuery } from '../requests';
import { createPatch, request, orderStatusValues } from '@/core';

const getOrderCollectionsAction = async({ commit }) => {
	let orderCollectionDtos = await getOrderCollections();
	commit('setQueryStatus', null); //queryStatus null = active orderCollections
	commit('setOrderCollections', orderCollectionDtos);
};
const getOrderCollectionsByQueryAction = async({commit}, payload) => {
	let orderCollectionDtos = await getOrderCollectionsByQuery(payload);
	commit('setOrderCollections', orderCollectionDtos);
};
const getDeletedOrderCollectionsAction = async({ commit }) => {
	let orderCollectionDtos = await getDeletedOrderCollections();
	commit('setQueryStatus', -10);
	commit('setOrderCollections', orderCollectionDtos);
};
const getCompletedOrderCollectionsAction = async({ commit }) => {
	let orderCollectionDtos = await getCompletedOrderCollections();
	commit('setQueryStatus', 100);
	commit('setOrderCollections', orderCollectionDtos);
};

const reloadSelectedOrderCollection = async({ commit }, payload) => {
	let orderCollectionDto = await getOrderCollectionById(payload);
	commit('updateSelectedOrderCollectionData', orderCollectionDto);
	commit('setSelectedOrderCollection', orderCollectionDto);

	// Other values that need to be cleared
	commit('setSelectedOrderCollectionUploadedFiles', []);
};

const getOrderCollectionChangelog = async({state, commit}, payload) => {
	var orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === payload)[0];
	if(!orderCollection) return;
	var url = `/orderCollections/${orderCollection.id}/changelog`;
	let changelog = await request({
		method: 'get',
		url: url,
		data: {}
	}).catch(e => {
		console.log(e.message);
	});
	commit('setSelectedOrderCollectionChangelog', changelog);
	return changelog;
};

const saveDraft = async({ dispatch, commit, state }) => {
	// commit('startLoading', 'saveDraft');
	let selectedOrderCollection = Object.assign({}, state.selectedOrderCollectionData);
	delete selectedOrderCollection.hasChanges;
	delete selectedOrderCollection.hasPriceChanges;
	delete selectedOrderCollection.hasEstimatedPriceChanges;

	let originalOrderCollection = await getOrderCollectionById(selectedOrderCollection.id);
	let patch = createPatch(originalOrderCollection, selectedOrderCollection);
	// console.log(patch);
	let url = `/orderCollections/${selectedOrderCollection.id}`;
	try {
		await request({
			method: 'patch',
			url: url,
			data: patch
		});
		// await dispatch('saveUploadedFiles', selectedOrderCollection);
		await dispatch('getOrderCollectionChangelog', selectedOrderCollection.id);
		commit('toggleHasChanges', {id: selectedOrderCollection.id, state: false});
		commit('toggleHasPriceChanges', {id: selectedOrderCollection.id, state: false});
		commit('toggleHasEstimatedPriceChanges', {id: selectedOrderCollection.id, state: false});
	} catch (e) {
		console.log(e.message);
		throw new Error(e);
	}
	// commit('stopLoading', 'saveDraft');
};
const saveMultipleDraft = async({ state }) => {
	// // commit('startLoading', 'saveDraft');
	for(let orderCollection of state.selectedOrderCollections){
		let selectedOrderCollection = Object.assign({}, orderCollection);

		let originalOrderCollection = await getOrderCollectionById(orderCollection.id);
		let patch = createPatch(originalOrderCollection, selectedOrderCollection);
		console.log(patch);
		// let url = `/orderCollections/${selectedOrderCollection.id}`; //kundeKode
		try {
			// await request({
			// 	method: 'patch',
			// 	url: url,
			// 	data: patch
			// });
		} catch (e) {
			console.log(e.message);
			throw new Error(e);
		}
	}
	// // commit('stopLoading', 'saveDraft');
};

const sendOrderCollectionToTMS = async({ dispatch, state }, payload) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === payload)[0];
	// var url =  `/orderCollections/${orderCollection.id}/send`; //kundeKode
	if(!orderCollection){
		return;
	} else if (orderCollection.hasChanges){
		await dispatch('saveBeforeSend', orderCollection);
	}
	try{
		// console.log(url, payload);
		// await request({
		// 	method: 'post',
		// 	url: url,
		// 	data: payload
		// });
	} catch (e) {
		console.log(e.message);
	}
};

const sendOrderCollectionToConfirm = async({ dispatch, state }, {orderCollectionId, confirmInformation}) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	// console.log(orderCollectionId, orderCollection);
	var url =  `/orderCollections/${orderCollectionId}/sendConfirm`; //kundeKode
	if(!orderCollection){
		return;
	} else if (orderCollection.hasChanges){
		await dispatch('saveBeforeSend', orderCollection);
	}
	try{
		await request({
			method: 'post',
			url: url,
			data: confirmInformation
		});
	} catch (e) {
		console.log(e.message);
	}
};

const flagSeenByUser = async({rootState, commit}, {orderCollectionId, logId}) => {
	let orderCollection = rootState.orderCollections.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(!orderCollection) return;
	let orderCollectionSeenBy = orderCollection.seenBy || [];
	let userId = rootState.user.currentUser.uid;
	let userInstance = orderCollectionSeenBy.find(x => x.userId === userId) || {};
	if(userInstance.logId === logId) return;
	let url = `/orderCollections/${orderCollection.id}/flagSeen`;
	try{
		await request({
			method: 'post',
			url: url,
			data: {logId}
		});
		commit('setSelectedOrderCollectionChangeMap', {});
	} catch (e) {
		console.log(e.message);
	}
};

const uploadFilesToAws = async() => {
// const uploadFilesToAws = async({commit, state}, {newFiles, orderCollection}) => {
	// var existingFiles = state.selectedOrderCollectionUploadedFiles;
	// // var url = `/orderCollections/${orderCollection.id}/uploadFiles`;
	// try{
	// 	// let uploadedFiles = await request({
	// 	// 	method: 'post',
	// 	// 	url: url,
	// 	// 	data: newFiles
	// 	// });
	// 	// commit('setSelectedOrderCollectionUploadedFiles', existingFiles.concat(uploadedFiles));
	// } catch (e) {
	// 	console.log(e.message);
	// }
};

const saveUploadedFiles = async() => {
// const saveUploadedFiles = async({commit, state}, orderCollection) => {
// 	var files = state.selectedOrderCollectionUploadedFiles;
// 	if(!files.length) return;
// 	// var url = `/orderCollections/${orderCollection.id}/saveFiles`;
// 	try{
// 		// await request({
// 		// 	method: 'post',
// 		// 	url: url,
// 		// 	data: files
// 		// });
// 		commit('setSelectedOrderCollectionUploadedFiles', []);
// 	} catch (e) {
// 		console.log(e.message);
// 	}
};

const finalizeOrderCollection = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		// await commit('selectedOrderCollectionSetStatus', 100);
		await commit('setStatus', {id: orderCollectionId, status: 100});
		await dispatch('saveDraft');
		await commit('deleteFromStateOrderCollections', orderCollectionId);
	}
};

const deleteOrderCollection = function({dispatch, commit, state}, orderCollectionId) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('setStatus', {id: orderCollectionId, status: -10});
		dispatch('saveBeforeSend', orderCollection);
		commit('deleteFromStateOrderCollections', orderCollectionId);
	}
};
const restoreOrderCollection = function({dispatch, commit, state}, orderCollectionId) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('setStatus', {id: orderCollectionId, status: 0});
		dispatch('saveBeforeSend', orderCollection);
		commit('deleteFromStateOrderCollections', orderCollectionId);
	}
};

const acceptOrderCollection = async({dispatch, commit, state, rootState}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		if(orderCollection.customerSettings.requiresCodeOfConduct){
			commit('toggleCocAccepted',  {id: orderCollectionId, state: true});
		}
		commit('setHandledBy', {orderCollectionId: orderCollectionId, currentUser: rootState.user.currentUser});
		commit('setStatus', {id: orderCollectionId, status: orderStatusValues.awaitingEstimatedPrice});
		dispatch('importEstimatedPriceList');
		dispatch('saveBeforeSend', orderCollection);
	}
};
const refuseOrderCollection = async({dispatch, commit, state, rootState}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('setHandledBy', {orderCollectionId: orderCollectionId, currentUser: rootState.user.currentUser});
		commit('setStatus', {id: orderCollectionId, status: -15});
		dispatch('saveBeforeSend', orderCollection);
		commit('deleteFromStateOrderCollections', orderCollectionId);
	}
};
//Accepts existing prices
const acceptPrices = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('transferPrices', true);
		commit('setStatus', {id: orderCollectionId, status: 30});
		dispatch('savePrices', orderCollection);
	}
};
//Transfers estimated priceitems to actual, and edits
const requestPrices = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('transferPrices', false);
		commit('setStatus', {id: orderCollectionId, status: 20});
		dispatch('savePrices', orderCollection);
	}
};
//Resets status from awaiting approval to awaiting price, which allows editing priceitems
const editPrices = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('setStatus', {id: orderCollectionId, status: 20});
		dispatch('savePrices', orderCollection);
	}
};
//Approves new prices
const approvePrices = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('setStatus', {id: orderCollectionId, status: 25});
		dispatch('savePrices', orderCollection);
	}
};
const savePrices = async({ dispatch, commit, state }) => {
	let selectedOrderCollection = Object.assign({}, state.selectedOrderCollectionData);
	delete selectedOrderCollection.hasPriceChanges;
	let originalOrderCollection = await getOrderCollectionById(selectedOrderCollection.id);
	let patch = createPatch(originalOrderCollection, selectedOrderCollection);

	//Only apply patches related to prices and status
	patch = patch.filter(x => x.path.includes('/priceItems/') || x.path.endsWith('/status'));
	// console.log(patch);

	let url = `/orderCollections/${selectedOrderCollection.id}`; //kundeKode
	try {
		await request({
			method: 'patch',
			url: url,
			data: patch
		});
		await dispatch('getOrderCollectionChangelog', selectedOrderCollection.id);
		commit('toggleHasPriceChanges', {id: selectedOrderCollection.id, state: false});
	} catch (e) {
		console.log(e.message);
		throw new Error(e);
	}
};
//Set existing estimated prices to accepted
const acceptEstimatedPrices = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('transferEstimatedPrices', true);
		commit('setStatus', {id: orderCollectionId, status: orderStatusValues.accepted});
		dispatch('saveEstimatedPrices', orderCollection);
	}
};
//Set estimated priceitems to not accepted, and edits
const requestEstimatedPrices = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('transferEstimatedPrices', false);
		commit('setStatus', {id: orderCollectionId, status: orderStatusValues.awaitingEstimatedPrice});
		dispatch('importEstimatedPriceList');
		dispatch('saveEstimatedPrices', orderCollection);
	}
};
//Resets status from awaiting approval to awaiting price, which allows editing priceitems
const editEstimatedPrices = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('setStatus', {id: orderCollectionId, status: orderStatusValues.awaitingEstimatedPrice});
		dispatch('importEstimatedPriceList');
		dispatch('saveEstimatedPrices', orderCollection);
	}
};
//Approves new estimated prices
const approveEstimatedPrices = async({dispatch, commit, state}, orderCollectionId) => {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		commit('setStatus', {id: orderCollectionId, status: orderStatusValues.awaitingEstimatedApproval});
		dispatch('saveEstimatedPrices', orderCollection);
	}
};
const saveEstimatedPrices = async({ dispatch, commit, state }) => {
	let selectedOrderCollection = Object.assign({}, state.selectedOrderCollectionData);
	delete selectedOrderCollection.hasEstimatedPriceChanges;
	let originalOrderCollection = await getOrderCollectionById(selectedOrderCollection.id);
	let patch = createPatch(originalOrderCollection, selectedOrderCollection);

	//Only apply patches related to prices and status
	patch = patch.filter(x => x.path.includes('/estimatedPriceItems/') || x.path.endsWith('/status'));
	// console.log(patch);

	let url = `/orderCollections/${selectedOrderCollection.id}`; //kundeKode
	try {
		await request({
			method: 'patch',
			url: url,
			data: patch
		});
		await dispatch('getOrderCollectionChangelog', selectedOrderCollection.id);
		commit('toggleHasEstimatedPriceChanges', {id: selectedOrderCollection.id, state: false});
	} catch (e) {
		console.log(e.message);
		throw new Error(e);
	}
};
const importEstimatedPriceList = async({ commit, state, rootState}) => {
	let orderCollection = state.selectedOrderCollectionData;
	if(!orderCollection) return;
	if((orderCollection.priceItems || []).length) return; // If priceItems not empty, do not import priceList
	let priceLists = rootState.user.transporterSettings.priceLists;
	let orderCollectionPriceLists = priceLists.filter( l => l.listType === 'orderCollection');
	let actualPriceLists = orderCollectionPriceLists.filter( l => l.priceType === 'estimated');
	let customerPriceList = actualPriceLists.find( l => l.customer === orderCollection.customerSettings.code);
	if(!customerPriceList || !customerPriceList.priceItems) return;
	for(let priceItem of customerPriceList.priceItems){
		commit('addEstimatedPriceItemToOrderCollection', priceItem);
	}
};

const saveBeforeSend = async({dispatch, commit, state}, payload) => {
	if(!state.selectedOrderCollectionData || state.selectedOrderCollectionData.id !== payload.id){
		commit('setSelectedOrderCollection', payload);
		dispatch('saveDraft');
		commit('clearSelectedOrderCollection');
	} else {
		dispatch('saveDraft');
	}
};

export default {
	getOrderCollections: getOrderCollectionsAction,
	getOrderCollectionsByQuery: getOrderCollectionsByQueryAction,
	getDeleted: getDeletedOrderCollectionsAction,
	getCompleted: getCompletedOrderCollectionsAction,
	reloadSelectedOrderCollection,
	getOrderCollectionChangelog,
	saveDraft,
	saveMultipleDraft,
	sendOrderCollectionToTMS,
	sendOrderCollectionToConfirm,
	flagSeenByUser,
	uploadFilesToAws,
	saveUploadedFiles,
	finalizeOrderCollection,
	saveBeforeSend,
	deleteOrderCollection,
	restoreOrderCollection,
	acceptOrderCollection,
	refuseOrderCollection,
	acceptPrices,
	requestPrices,
	editPrices,
	approvePrices,
	savePrices,
	acceptEstimatedPrices,
	requestEstimatedPrices,
	editEstimatedPrices,
	approveEstimatedPrices,
	saveEstimatedPrices,
	importEstimatedPriceList,
};

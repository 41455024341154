import {validate} from '@/core';

const getOrderCollectionHasChanges = (state) => {
	if(!state.selectedOrderCollectionData){
		return false;
	}
	let hasChanges = state.selectedOrderCollectionData.hasChanges;
	let hasPriceChanges = state.selectedOrderCollectionData.hasPriceChanges;
	let hasEstimatedPriceChanges = state.selectedOrderCollectionData.hasEstimatedPriceChanges;

	return !!(hasChanges||hasPriceChanges||hasEstimatedPriceChanges);
};

const getOrderCollectionReadyToSend = (state) => {
	let readyToSend = false;
	if(state.selectedOrderCollectionData !== null){
		let orderCollection = state.selectedOrderCollectionData;
		if(
			orderCollection.ata.date !== null &&
			orderCollection.atd.date !== null &&
			orderCollection.status < 100 && orderCollection.status >= 30
		){
			readyToSend = true;
		}
	}
	return readyToSend;
};

const getOrderCollectionReadyToFinalize = (state, getters, rootState) => {
	let readyToFinalize = false;
	if(state.selectedOrderCollectionData !== null){
		let orderCollection = state.selectedOrderCollectionData;
		let relatedOrders = orderCollection.orders;
		let validators = rootState.user.transporterSettings.validators.finalizeOrder;
		if(
			validate(orderCollection, validators) &&
			relatedOrders.every(o => validate(o, validators)) &&
			orderCollection.status < 80 && orderCollection.status >= 30
		){
			readyToFinalize = true;
		}
	}
	return readyToFinalize;
};

const getTransporterSettings = (state, getters, rootState) => {
	return rootState.user.transporterSettings;
};

const isLoading = (state) => (id) => {
	return state.loading.includes(id);
};

export default {
	getOrderCollectionHasChanges,
	getOrderCollectionReadyToSend,
	getOrderCollectionReadyToFinalize,
	getTransporterSettings,
	isLoading,
};

const { request } = require('@/core');

export async function getOrderCollections() {
	let response = await request({
		method: 'get',
		url: '/orderCollections',
		params: {
			status_ge: -5,
			status_lt: 100
		}
	});
	return response;
}

export async function getOrderCollectionById(orderCollectionId) {
	let response = await request({
		method: 'get',
		url: `/orderCollections/${orderCollectionId}`,
	});
	return response;
}

export async function getOrderCollectionsByQuery(params) {
	let response = await request({
		method: 'get',
		url: '/orderCollections',
		params: {
			...params
		}
	});
	return response;
}

export async function getDeletedOrderCollections() {
	let response = await request({
		method: 'get',
		url: '/orderCollections',
		params: {
			status_lt: 0
		}
	});
	return response;
}

export async function getCompletedOrderCollections() {
	let response = await request({
		method: 'get',
		url: '/orderCollections',
		params: {
			status_ge: 100
		}
	});
	return response;
}

export async function updateOrderCollection() {
	return null;
}

export async function sendOrderCollection() {
	return null;
}

<template>
	<div class="componentContainer">
		<div class="componentWrapper" style="grid-area:from;">
			<div class="componentHeader">
				From
			</div>
			<div class="componentContent" v-html="this.addressRender(this.orderCollectionData.from)">
			</div>
		</div>
		<div class="componentWrapper" style="grid-area:to;">
			<div class="componentHeader">
				To
			</div>
			<div class="componentContent" v-html="this.addressRender(this.orderCollectionData.to)">
			</div>
		</div>
		<div class="componentWrapper half-width" style="grid-area:toIata;">
			<div class="componentHeader">
				Airport
			</div>
			<b-form-input
				size="sm"
				title="Airport"
				type="text"
				class="border"
				:disabled="!editable || disableFlight"
				:value="toIata"
				@change="onToIataChanged($event)"
			></b-form-input>
		</div>
		<div class="componentWrapper half-width" style="grid-area:departingTerminal;">
			<div class="componentHeader">
				Departing terminal
			</div>
			<b-input-group>
				<b-form-select
					class="modalSelect border"
					:class="{ 'font-italic': !departingTerminalSelected}"
					size="sm"
					title="Departing terminal"
					v-model="departingTerminalSelected"
					@change="onDepartingTerminalChanged($event)"
					:disabled="!editable || disableFlight"
				>
					<option class="font-italic" :value="null"></option>
					<option
						v-for="(terminal, index) in departingTerminals"
						style="font-style:normal;"
						:key="index"
						:value="terminal.value"
						v-text="terminal.text"
					/>
				</b-form-select>
			</b-input-group>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from '../store';
export default {
	name: 'ComponentFrom',
	props: ['editable'],
	data() {
		return {
			departingTerminalSelected: null,
		};
	},
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData',
			departingTerminals: 'departingTerminals'
		}),
		relatedOrders() {
			return this.orderCollectionData.orders;
		},
		toIata() {
			return this.orderCollectionData.to.iataCode || null;
		},
		departingTerminal() {
			return this.orderCollectionData.departingTerminal || null;
		},
		disableFlight(){
			if(
				this.orderCollectionData.vehicleLicensePlate ||
				this.orderCollectionData.vehicleNationality ||
				this.orderCollectionData.vehicleDriverName ||
				this.orderCollectionData.vehicleType
			) {
				return true;
			}
			return false;
		},
	},
	methods: {
		...mapMutations([
			'toggleHasChanges',
			'updateSelectedOrderCollectionData',
		]),
		onToIataChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.to.iataCode !== value){
				this.$set(this.orderCollectionData.to, 'iataCode', value);
				this.updateRelatedOrderIata(value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onDepartingTerminalChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.departingTerminal !== value){
				this.$set(this.orderCollectionData, 'departingTerminal', value);
				this.updateRelatedOrderData('departingTerminal', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		updateRelatedOrderData(key, value) {
			for(var order of this.relatedOrders){
				this.$set(order, key, value);
			}
		},
		updateRelatedOrderIata(value) {
			for(var order of this.relatedOrders){
				let party = order.deliveryParty ? 'deliveryParty' : 'consignee';
				this.$set(order[party], 'iataCode', value);
			}
		},
		addressRender(party) {
			var firstLine = [party.name, party.address1].filter(x => !!x);
			firstLine = firstLine.join(', ');
			var secondLine = [party.address2, party.address3].filter(x => !!x);
			secondLine = secondLine.join(', ');
			var thirdLine = [party.countryCode, party.postalCode].filter(x => !!x);
			thirdLine = thirdLine.join('-');
			thirdLine = [thirdLine, party.postalPlace, party.iataCode].filter(x => !!x);
			thirdLine = thirdLine.join(', ');
			return `
				<div class="addressLine">${firstLine}</div>
				<div class="addressLine">${secondLine}</div>
				<div class="addressLine">${thirdLine}</div>
			`;
		},
	},
	beforeMount() {
		this.departingTerminalSelected = this.departingTerminal;
	}
};
</script>

<style lang="scss" scoped>
.componentContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'from' 'to' 'departingTerminal' 'toIata';
}
@media (min-width: 768px) {
	.componentContainer {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas:
			'from to'
			'departingTerminal toIata';
	}
}
.componentHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 1rem;
}
.componentWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.componentContent {
	text-align: left;
	margin-bottom:5px;
	line-height: 15px;
}
.componentContent.bold {
	font-weight: bold;
}
.componentContent /deep/ .addressLine {
    font-size: 12px;
}
.modalSelect {
	padding-top: 0;
	padding-bottom: 0;
}
.componentWrapper .border { //Quickfix, fix later
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.componentWrapper.half-width {
	width: 50%;
}
</style>


<template>
	<div class="summaryWrapper border">
		<div class="summaryHeader">
			Changelog
		</div>
		<ul class="changelog">
			<li
				v-for="changes in changelog"
				:key="changes.createdAt"
			>
				<div class="timestamp">
					<div>{{ changes.createdAt }}</div>
					<div
						v-if="changes.executedBy"
						v-text="` - ${changes.executedBy.name}`"
					></div>
				</div>
				<div>
					<ul class="change">
						<li
							v-for="(change, index) in changes.data"
							:key="index"
						>
							<span>{{change}}</span>
						</li>
					</ul>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState, mapActions } from '../store';

export default {
	name: 'ComponentChangelog',
	props: ['editable'],
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData',
			changelog: 'selectedOrderCollectionChangelog'
		}),
	},
	methods: {
		...mapActions([
			'getOrderCollectionChangelog'
		])
	},
	async beforeMount() {
		// this.changelog = await this.getOrderCollectionChangelog(this.orderCollectionData.id);
		await this.getOrderCollectionChangelog(this.orderCollectionData.id);
	}
};
</script>

<style  lang="scss" scoped>
.summaryHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.summaryWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
}
.summaryWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.summaryWrapper.flex-fill {
	flex: 1;
}
.changelog {
	margin: 1rem;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
}
.changelog li {
	display: block;
	width: 100%;
	margin: 0.3rem;
	text-align: left;
}
.changelog > li > div {
	display: inline-block;
	vertical-align: top;
}
.changelog .change {
	display: inline-block;
	padding-left: 1.5rem;
}
.changelog .change li {
	margin: 0;
}
.summaryContainer {
	margin: 1rem;
}
</style>


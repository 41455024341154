<template>
	<div class="componentWrapper border">
		<div class="componentHeader">
			Contact
		</div>
		<div class="componentContent flex-fill" v-if="this.orderCollectionData.contact">
			<ul class="contactList">
				<li>
					<font-awesome-icon icon="user" class="fa-md"/>
					<span>{{ this.orderCollectionData.contact.name }}</span>
				</li>
				<li>
					<font-awesome-icon icon="phone" class="fa-md"/>
					<span><a :href="`tel:${this.trimClerkPhone}`">{{ this.orderCollectionData.contact.phone }}</a></span>
				</li>
				<li>
					<font-awesome-icon icon="at" class="fa-md"/>
					<span><a :href="`mailto:${this.orderCollectionData.contact.email}`">{{ this.orderCollectionData.contact.email }}</a></span>
				</li>
			</ul>
		</div>
		<div class="componentContent flex-fill" v-else>
			<ul class="contactList">
				<li>
					<span>No contact information</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapState } from '../store';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
	name: 'ComponentContact',
	components: {
		FontAwesomeIcon
	},
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData'
		}),
		trimClerkPhone(){
			if(!this.orderCollectionData.contact) return null;
			if(!this.orderCollectionData.contact.phone)
				return null;
			return this.orderCollectionData.contact.phone.replace('+', '00').replace(/\s/g, '');
		},
	}
};
</script>

<style lang="scss" scoped>
.componentHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
	display:flex;
}
.componentWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.componentContent {
	text-align: left;
	line-height: 15px;
	display: flex;
}
.componentContent.bold {
	font-weight: bold;
}
.contactList {
	margin: 1rem;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
}
.contactList li {
	display: inline-block;
	margin: 0.3rem;
	min-width: calc( 100% - 0.6rem);
	text-align: left;
}
.contactList li > span {
	margin-left: 0.5rem;
}
.referenceHeader {
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper.flex-fill {
	flex: 1;
}
</style>

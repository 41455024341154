import Vue from 'vue';
import {newUUID} from 'core';
import rewriteChangelog from './rewriteChangelog';

const setOrderCollections = function(state, orderCollectionDtos) {
	Vue.set(state, 'orderCollections', orderCollectionDtos);
};
const setSelectedOrderCollection = function(state, orderCollection) {
	Vue.set(state, 'selectedOrderCollectionData', orderCollection);
};
const clearSelectedOrderCollection = function(state) {
	Vue.set(state, 'selectedOrderCollectionData', null);
};
const setSelectedOrderCollections = function(state, orderCollections) {
	Vue.set(state, 'selectedOrderCollections', orderCollections);
};
const updateSelectedOrderCollectionData = function(state, orderCollection){
	for(var i = 0; i < state.orderCollections.length; i++){
		if(state.orderCollections[i].id === orderCollection.id){
			Vue.set(state.orderCollections, i, orderCollection);
			return;
		}
	}
};
const toggleHasChanges = function(state, payload) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === payload.id)[0];
	if(orderCollection){
		Vue.set(orderCollection, 'hasChanges', payload.state);
	}
};
const toggleHasPriceChanges = function(state, payload) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === payload.id)[0];
	if(orderCollection){
		Vue.set(orderCollection, 'hasPriceChanges', payload.state);
	}
};
const toggleHasEstimatedPriceChanges = function(state, payload) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === payload.id)[0];
	if(orderCollection){
		Vue.set(orderCollection, 'hasEstimatedPriceChanges', payload.state);
	}
};
const toggleCocAccepted = function(state, payload) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === payload.id)[0];
	if(orderCollection){
		Vue.set(orderCollection, 'cocAccepted', payload.state);
		for(var order of orderCollection.orders){
			Vue.set(order, 'cocAccepted', payload.state);
		}
	}
};
const setHandledBy = function(state, {orderCollectionId, currentUser}) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		let handledBy = { email: currentUser.email, displayName: currentUser.displayName};
		Vue.set(orderCollection, 'handledBy', handledBy);
		for(var order of orderCollection.orders){
			Vue.set(order, 'handledBy', handledBy);
		}
	}
};

const startLoading = function(state, payload) {
	state.loading.push(payload);
};
const stopLoading = function(state, payload) {
	state.loading.pop(payload);
};

const setStatus = function(state, {id, status}) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === id)[0];
	if(orderCollection){
		Vue.set(orderCollection, 'status', status);
		for(var order of orderCollection.orders){
			Vue.set(order, 'status', status);
		}
	}
};
const deleteFromStateOrderCollections = function(state, orderCollectionId) {
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		Vue.delete(state.orderCollections, state.orderCollections.map( o => {return o.id;}).indexOf(orderCollectionId));
	}
};

const selectedOrderCollectionSetStatus = function(state, status){
	Vue.set(state.selectedOrderCollectionData, 'status', status);
};
const transferPrices = function(state, isAccepted){
	if(!state.selectedOrderCollectionData) return;
	//OrderCollections do not have estimated prices
	//Just transfer for related orders as ordinary prices
	let related = state.selectedOrderCollectionData.orders;
	for(let order of related) {
		let est = order.transporter.estimatedPriceItems;
		let act = order.transporter.priceItems;
		for(let price of est){
			let {newPrice} = newPriceItem(price, isAccepted);
			act.push(newPrice);
		}
	}
};
const spliceEmptyPrices = function(state, orderCollectionId){
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		let act = orderCollection.priceItems || [];
		let related = state.selectedOrderCollectionData.orders;
		let empty = act.filter(p => (!p.description && !p.type && !p.netAmount)).map(p => p.id) || [];
		Vue.set(orderCollection, 'priceItems', act.filter(p => !empty.includes(p.id)));
		for(let order of related){
			act = order.transporter.priceItems;
			Vue.set(order.transporter, 'priceItems', act.filter(p => !empty.includes(p.parentId)));
		}
	}
};
const addEmptyPriceItemToOrderCollection = function(state){
	if(!state.selectedOrderCollectionData) return;
	if(!state.selectedOrderCollectionData.priceItems) Vue.set(state.selectedOrderCollectionData, 'priceItems', []);
	var act = state.selectedOrderCollectionData.priceItems;
	var related = state.selectedOrderCollectionData.orders;
	var newPrice = newPriceItem();
	act.push(newPrice);
	for(let order of related){
		if(!order.transporter.priceItems) Vue.set(order.transporter, 'priceItems', []);
		order.transporter.priceItems.push(newRelatedPriceItem(newPrice.id,false));
	}
};
const removePriceItemFromOrderCollection = function(state, priceId){
	if(!state.selectedOrderCollectionData) return;
	var act = state.selectedOrderCollectionData.priceItems;
	var related = state.selectedOrderCollectionData.orders;
	Vue.set(state.selectedOrderCollectionData, 'priceItems', act.filter(p => p.id !== priceId));
	for(let order of related){
		act = order.transporter.priceItems;
		Vue.set(order.transporter, 'priceItems', act.filter(p => p.parentId !== priceId));
	}
};

const transferEstimatedPrices = function(state, isAccepted){
	if(!state.selectedOrderCollectionData) return;
	//OrderCollections do not have estimated prices
	//Just transfer for related orders as ordinary prices
	let estimatedPrices = state.selectedOrderCollectionData.estimatedPriceItems;
	for(let price of estimatedPrices) {
		Vue.set(price, 'isAccepted', isAccepted);
	}
};
const spliceEmptyEstimatedPrices = function(state, orderCollectionId){
	let orderCollection = state.orderCollections.filter(orderCollection => orderCollection.id === orderCollectionId)[0];
	if(orderCollection){
		let est = orderCollection.estimatedPriceItems;
		let empty = est.filter(p => (!p.description && !p.type && !p.netAmount)).map(p => p.id);
		Vue.set(orderCollection, 'estimatedPriceItems', est.filter(p => !empty.includes(p.id)));
	}
};
const addEmptyEstimatedPriceItemToOrderCollection = function(state){
	if(!state.selectedOrderCollectionData) return;
	if(!state.selectedOrderCollectionData.estimatedPriceItems) Vue.set(state.selectedOrderCollectionData, 'estimatedPriceItems', []);
	var est = state.selectedOrderCollectionData.estimatedPriceItems;
	var newPrice = newPriceItem();
	est.push(newPrice);
};
const addEstimatedPriceItemToOrderCollection = function(state, priceItem){
	if(!state.selectedOrderCollectionData) return;
	if(!state.selectedOrderCollectionData.estimatedPriceItems) Vue.set(state.selectedOrderCollectionData, 'estimatedPriceItems', []);
	var est = state.selectedOrderCollectionData.estimatedPriceItems;
	var newPrice = newPriceItem(priceItem);
	est.push(newPrice);
};
const removeEstimatedPriceItemFromOrderCollection = function(state, priceId){
	if(!state.selectedOrderCollectionData) return;
	var est = state.selectedOrderCollectionData.estimatedPriceItems;
	Vue.set(state.selectedOrderCollectionData, 'estimatedPriceItems', est.filter(p => p.id !== priceId));
};

//Only used internally in mutations. Not to be exported
const newPriceItem = function(price = null, isAccepted = false){
	let newPrice = {
		code: null,
		type: 'frakt',
		currency: 'NOK',
		description: null,
		id: newUUID(),
		lineNo: 0,
		netAmount: null,
		grossAmount: null,
		transporterId: null,
		vat: 0,
		isAccepted: isAccepted,
		distribution: 'weight',
		basePrice: null,
		basePriceUnit: null,
		retainAccepted: false,
	};

	//Only true if transfering estimated prices from orders,
	//so it is not a related price
	if(price) {
		newPrice.code = price.code;
		newPrice.type = price.type;
		newPrice.currency = price.currency;
		newPrice.description = price.description;
		newPrice.netAmount = price.netAmount;
		newPrice.grossAmount = price.netAmount;
		newPrice.transporterId = price.transporterId;
		newPrice.distribution = price.distribution;
		newPrice.basePrice = price.basePrice;
		newPrice.basePriceUnit = price.basePriceUnit;
	}
	return newPrice;
};
//Only used internally in mutations. Not to be exported
const newRelatedPriceItem = function(parentId, isAccepted){
	let newRelatedPrice = {
		code: null,
		type: 'frakt',
		currency: 'NOK',
		description: null,
		id: newUUID(),
		lineNo: 0,
		netAmount: null,
		grossAmount: null,
		transporterId: null,
		aggregation: null,
		basePrice: null,
		basePriceUnit: null,
		vat: 0,
		isAccepted: isAccepted,
		parentId: parentId
	};
	return newRelatedPrice;
};

const logFunction = function(){
	console.log('debugMark');
};
const setQueryStatus = function(state, status) {
	state.queryStatus = status;
};

const setSelectedOrderCollectionChangelog = function(state, changelog){
	let log = rewriteChangelog(changelog);
	log = log.sort( (a, b) => {
		return Date.parse(b.createdAt) - Date.parse(a.createdAt);
	});
	Vue.set(state, 'selectedOrderCollectionChangelog', log);
};

const setSelectedOrderCollectionUploadedFiles = function(state, files){
	state.selectedOrderCollectionUploadedFiles = files;
};
const updateUploadedFileAttribute = function(state, {attr, value, index}) {
	Vue.set(state.selectedOrderCollectionUploadedFiles[index], attr, value);
};
const removeUploadedFile = function(state, index) {
	state.selectedOrderCollectionUploadedFiles.splice(index, 1);
};

const setStoredGridOptions = function(state, gridOptions) {
	state.gridOptions = gridOptions;
};
const setValidityChecked = function(state, val){
	state.validityChecked = val;
};

const setOrderCollectionChangeMap = function(state, {orderCollectionId, changeMap}){
	let orderCollection = state.orderCollections.find(orderCollection => orderCollection.id === orderCollectionId);
	if(orderCollection){
		Vue.set(orderCollection, 'changeMap', changeMap);
	}
};
const setSelectedOrderCollectionChangeMap = function(state, changeMap){
	Vue.set(state.selectedOrderCollectionData, 'changeMap', changeMap);
};

export default {
	setOrderCollections,
	setSelectedOrderCollection,
	clearSelectedOrderCollection,
	setSelectedOrderCollections,
	toggleHasChanges,
	toggleHasPriceChanges,
	toggleHasEstimatedPriceChanges,
	toggleCocAccepted,
	setHandledBy,
	updateSelectedOrderCollectionData,
	startLoading,
	stopLoading,
	setStatus,
	deleteFromStateOrderCollections,
	selectedOrderCollectionSetStatus,
	transferPrices,
	spliceEmptyPrices,
	addEmptyPriceItemToOrderCollection,
	removePriceItemFromOrderCollection,
	transferEstimatedPrices,
	spliceEmptyEstimatedPrices,
	addEmptyEstimatedPriceItemToOrderCollection,
	addEstimatedPriceItemToOrderCollection,
	removeEstimatedPriceItemFromOrderCollection,
	logFunction,
	setQueryStatus,
	setSelectedOrderCollectionChangelog,
	setSelectedOrderCollectionUploadedFiles,
	updateUploadedFileAttribute,
	removeUploadedFile,
	setStoredGridOptions,
	setValidityChecked,
	setOrderCollectionChangeMap,
	setSelectedOrderCollectionChangeMap,
};

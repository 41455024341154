<template>
	<div class="sectionContainer">
		<component-references
			style="grid-area:references;"
		/>
		<component-transport-summary
			style="grid-area:summary"
		/>
		<component-contact
			style="grid-area:contact;"
		/>
	</div>
</template>

<script>
import ComponentReferences from './ComponentReferences.vue';
import ComponentContact from './ComponentContact.vue';
import ComponentTransportSummary from './ComponentTransportSummary.vue';
export default {
	name: 'ComponentSummary',
	props: ['editable'],
	components: {
		ComponentReferences,
		ComponentContact,
		ComponentTransportSummary,
	},
	data() {
		return {
			validState: {
			},
		};
	},
	computed: {
		informationValid(){
			for(var key in this.validState){
				if(!this.validState[key]) return false;
			}
			return true;
		},
	},
	watch: {
		informationValid: function(val) {
			this.$emit('validStateChanged', val);
		}
	},
};
</script>

<style lang="scss" scoped>
.sectionContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-areas:'references' 'summary' 'contact';
}
@media (min-width: 768px) {
	.sectionContainer {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas:
			'references summary'
			'references contact';
	}
}
</style>

<template>
	<div class="mainContent">
		<div class="mainHeader">
			<h1>LIST OF TRANSPORTNEEDS</h1>
			<div>
				<b-btn class="button" :class="{ 'active' : selected==='active'}" @click="onReloadOrderCollectionsClicked">Active Transportneeds</b-btn>
				<b-btn class="button" :class="{ 'active' : selected==='completed'}" @click="onLoadCompleted">Completed Transportneeds</b-btn>
				<b-btn class="button" :class="{ 'active' : selected==='deleted'}" @click="onLoadDeleted">Deleted Transportneeds</b-btn>
			</div>
		</div>
		<OrderCollectionsGrid style="grid-area:content" />
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from './store';
import OrderCollectionsGrid from './OrderCollectionsGrid.vue';

export default {
	name: 'OrderCollection',
	components: {
		OrderCollectionsGrid
	},
	data(){
		return {
			selected: 'active',
		};
	},
	computed: {
		...mapState([
			'orderCollections'
		])
	},
	methods: {
		...mapActions([
			'getOrderCollections',
			'getDeleted',
			'getCompleted'
		]),
		...mapMutations([
			'setSelectedOrderCollections'
		]),
		async onReloadOrderCollectionsClicked() {
			this.selected = 'active';
			await this.getOrderCollections();
			this.setSelectedOrderCollections([]);
		},
		async onLoadDeleted(){
			this.selected = 'deleted';
			await this.getDeleted();
			this.setSelectedOrderCollections([]);
		},
		async onLoadCompleted(){
			this.selected = 'completed';
			await this.getCompleted();
			this.setSelectedOrderCollections([]);
		}
	},
	mounted(){
		this.getOrderCollections();
	},
	beforeMounted() {

	}
};
</script>

<style lang="scss" scoped>
.button {
	margin: 5px 5px 0 5px;
}
.mainContent {
    display: grid;
	max-height: calc(100vh - 176px);
	height: calc(100vh - 176px);
	grid-template-rows: 142px auto;
	grid-template-areas: 'header' 'content';
}
@media (min-width: 425px) {
	.mainContent {
		max-height: calc(100vh - 136px);
		height: calc(100vh - 136px);
		grid-template-rows: 142px auto;
	}
}
@media (min-width: 768px) {
	.mainContent {
		max-height: calc(100vh - 56px);
		height: calc(100vh - 56px);
		grid-template-rows: 100px auto;
	}
}
.mainHeader {
	grid-area: header;
}
</style>

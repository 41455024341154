<template>
	<div class="componentWrapper border flex-fill">
		<div class="componentHeader">
			References
		</div>
		<div class="componentContent">
			<ul class="referenceList">
				<li>
					<div class="referenceHeader">Comment</div>
					<div>{{ this.orderCollectionData.comment}}</div>
				</li>
				<li>
					<div class="referenceHeader">Order Category</div>
					<div>{{ this.orderCollectionData.orderCategory }}</div>
				</li>
				<li>
					<div class="referenceHeader">Transporter Instructions</div>
					<div>
						<ul class="referenceList subList">
							<li v-for="order in orderData" :key="order.id">
								{{ order.transporterInstructions }}
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapState } from '../store';
export default {
	name:'ComponentReferences',
	components: {

	},
	data() {
		return {

		};
	},
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData'
		}),
		orderData() {
			return this.orderCollectionData.orders;
		}
	}
};
</script>

<style lang="scss" scoped>
.componentHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
	display:flex;
}
.componentWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.componentContent {
	text-align: left;
	margin-bottom:5px;
	line-height: 15px;
	display: flex;
}
.componentContent.bold {
	font-weight: bold;
}
.referenceList {
	margin: 1rem;
	padding-left: 0;
	display:flex;
	align-content: space-between;
	flex-flow: row wrap;
}
.referenceList li {
	display: inline-block;
	margin: 0.3rem;
	min-width: calc( 100% - 0.6rem);
	text-align: left;
}
.referenceList li > span {
	margin-left: 0.5rem;
}
.subList {
	margin: 0;
}
.referenceHeader {
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper.flex-fill {
	flex: 1;
}
</style>


import { createNamespacedHelpers } from 'vuex';
import BaseStore from '@/store';

import {
	documentTypes,
	nationality,
	priceTypes,
	priceDistributions,
	priceAggregations,
	tollsted as customsPoints,
	departingTerminals,
	orderStatusValues,
} from '@/core';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
	orderCollections: [],
	selectedOrderCollections: [],
	loading: [],
	documentTypes: documentTypes,
	nationality: nationality,
	priceTypes: priceTypes,
	priceDistributions: priceDistributions,
	priceAggregations: priceAggregations,
	departingTerminals: departingTerminals,
	customsPoints: customsPoints,
	orderStatusValues: orderStatusValues,
	selectedOrderCollectionData: null,
	selectedOrderCollectionChangelog: [],
	selectedOrderCollectionUploadedFiles: [],
	queryStatus: 0,
	gridOptions: null,
	validityChecked: false,
	defaultGridSort: [
		{colId: 'status', sort: 'asc'},
		{colId: 'etd', sort: 'asc'},
		{colId: 'from', sort: 'asc'}
	],
};

const store = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

BaseStore.registerModule('orderCollections', store);

export default BaseStore;

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('orderCollections');

export {
	mapState,
	mapGetters,
	mapMutations,
	mapActions
};

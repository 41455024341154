<template>
	<div class="componentContainer border flex-fill">
		<div class="componentHeader">
			Prices
		</div>
		<div class="componentContent" :class="{
			'estimated' : orderCollectionStatus == orderStatusValues.awaitingEstimatedPrice,
			'actual' : orderCollectionStatus == orderStatusValues.awaitingPrice,
		}">
			<div class="estimated" style="grid-area:estimated;">
				<span>
					Estimated
				</span>
				<ul class="priceList">
					<template v-if="orderCollectionStatus != orderStatusValues.awaitingEstimatedPrice">
						<li v-for="price in estimatedPriceItems" :key="price.id">
							<div class="priceItem estimated">
								<span
									style="grid-area:type;"
									class="type"
								>{{ getTypeDescription(price.type) }}:</span>
								<span
									style="grid-area:description;"
									class="description"
								>{{ price.description }}</span>
								<span
									style="grid-area:distribution;"
									class="description"
								>{{ price.type ? `Price distribution: ${getDistributionDescription(price.distribution)}` : '' }}</span>
								<span
									style="grid-area:price;"
									v-text="`${price.currency} ${price.basePrice || price.netAmount}`"
								/>
							</div>
						</li>
					</template>
					<template v-else>
						<li v-for="price in estimatedPriceItems" :key="price.id" class="new">
							<div class="priceItem estimated editable">
								<b-input-group :ref="price.id">
									<b-form-select
										style="grid-area:type;"
										class="type"
										size="sm"
										title="Price type"
										:value="price.type"
										@change="onEstimatedTypeChanged($event, price.id)"
										:disabled="!editable"
									>
										<option class="font-italic" :value="null" :disabled="true">Type</option>
										<option
											v-for="(type, index) in priceTypes" :key="index"
											:value="type.type"
											v-text="type.description"
										>
										</option>
									</b-form-select>
									<b-form-input
										style="grid-area:description;"
										size="sm"
										class="description"
										title="Price description"
										placeholder="Description"
										type="text"
										:value="price.description"
										:disabled="!editable"
										@change="onEstimatedDescriptionChanged($event, price.id)"
									></b-form-input>
									<b-form-select
										style="grid-area:distribution;"
										class="distribution"
										size="sm"
										title="Price distribution"
										:value="price.distribution"
										@change="onEstimatedDistributionChanged($event, price.id)"
										:disabled="!editable"
									>
										<option class="font-italic" :value="null" :disabled="true">Distribution</option>
										<option
											v-for="(type, index) in priceDistributions" :key="index"
											:value="type.type"
											:disabled="!type.applicable(orderCollectionData)"
											:class="{ 'font-italic' : !type.applicable(orderCollectionData) }"
											v-text="type.description"
										>
										</option>
									</b-form-select>
									<b-form-select
										style="grid-area:currency;"
										class="currency"
										size="sm"
										title="Price currency"
										:value="price.currency"
										@change="onEstimatedCurrencyChanged($event, price.id)"
										:disabled="!editable"
									>
										<option class="font-italic" value="NOK">NOK</option>
										<option class="font-italic" value="EUR">EUR</option>
									</b-form-select>
									<b-form-input
										v-if="!(getDistribution(price.distribution) || {}).unit"
										style="grid-area:net;"
										size="sm"
										class="amount"
										title="Net amount"
										placeholder="Net amount"
										type="number"
										step="any"
										:value="price.netAmount"
										:disabled="!editable"
										@change="onEstimatedNetChanged($event, price.id)"
									></b-form-input>
									<b-form-input
										v-else
										style="grid-area:net;"
										size="sm"
										class="amount"
										title="Base price"
										placeholder="Base price"
										type="number"
										step="any"
										:value="price.basePrice"
										:disabled="!editable"
										@change="onEstimatedBasePriceChanged($event, price.id)"
									></b-form-input>
								</b-input-group>
								<font-awesome-icon @click="removeEstimatedPriceItem(price.id)" style="color:#6c757d;" icon="times" class="fa-md close"/>
							</div>
						</li>
					</template>
				</ul>
			</div>
			<div class="actual" style="grid-area:actual;">
				<span>
					Prices for related orders
				</span>
				<ul class="priceList">
					<template v-if="orderCollectionStatus != orderStatusValues.awaitingPrice">
						<li v-for="price in priceItems" :key="price.id">
							<div class="priceItem">
								<span
									style="grid-area:type;"
									class="type"
								>{{ price.type ? `${getTypeDescription(price.type)}: ` : '' }}</span>
								<span
									style="grid-area:description;"
									class="description"
								>{{ price.description }}</span>
								<span
									style="grid-area:distribution;"
									class="description"
								>{{ price.type ? `Price distribution: ${getDistributionDescription(price.distribution)}` : '' }}</span>
								<span
									style="grid-area:price;"
								>{{ price.currency }} {{ price.netAmount }}</span>
								<span
									style="grid-area:accepted;"
									:class="{'accepted': price.isAccepted}"
								>
									<font-awesome-icon
										v-if="price.isAccepted"
										style="color:#28a745;"
										icon="check-circle"
										class="fa-md"
									/>
								</span>
							</div>
						</li>
					</template>
					<template v-else>
						<li v-for="price in priceItems" :key="price.id" class="new">
							<div class="priceItem editable">
								<b-input-group :ref="price.id">
									<b-form-select
										style="grid-area:type;"
										class="type"
										size="sm"
										title="Price type"
										:value="price.type"
										@change="onTypeChanged($event, price.id)"
										:disabled="!editable"
									>
										<option class="font-italic" :value="null" :disabled="true">Type</option>
										<option
											v-for="(type, index) in priceTypes" :key="index"
											:value="type.type"
											v-text="type.description"
										>
										</option>
									</b-form-select>
									<b-form-input
										style="grid-area:description;"
										size="sm"
										class="description"
										title="Price description"
										placeholder="Description"
										type="text"
										:value="price.description"
										:disabled="!editable"
										@change="onDescriptionChanged($event, price.id)"
									></b-form-input>
									<b-form-select
										style="grid-area:distribution;"
										class="distribution"
										size="sm"
										title="Price distribution"
										:value="price.distribution"
										@change="onDistributionChanged($event, price.id)"
										:disabled="!editable"
									>
										<option class="font-italic" :value="null" :disabled="true">Distribution</option>
										<option
											v-for="(type, index) in priceDistributions" :key="index"
											:value="type.type"
											:disabled="!type.applicable(orderCollectionData)"
											:class="{ 'font-italic' : !type.applicable(orderCollectionData) }"
											v-text="type.description"
										>
										</option>
									</b-form-select>
									<b-form-select
										style="grid-area:currency;"
										class="currency"
										size="sm"
										title="Price currency"
										:value="price.currency"
										@change="onCurrencyChanged($event, price.id)"
										:disabled="!editable"
									>
										<option class="font-italic" value="NOK">NOK</option>
										<option class="font-italic" value="EUR">EUR</option>
									</b-form-select>
									<b-form-input
										style="grid-area:net;"
										size="sm"
										class="amount"
										title="Net amount"
										placeholder="Net amount"
										type="number"
										step="any"
										:value="price.netAmount"
										:disabled="!editable"
										@change="onNetChanged($event, price.id)"
									></b-form-input>
								</b-input-group>
								<font-awesome-icon @click="removePriceItem(price.id)" style="color:#6c757d;" icon="times" class="fa-md close"/>
							</div>
						</li>
					</template>
				</ul>
			</div>
			<div class="buttonContainer" style="grid-area:buttonContainer;">
				<template v-if="orderCollectionStatus == orderStatusValues.awaitingEstimatedPrice">
					<b-btn @click="addEstimatedPriceItem" class="b-btn" size="sm" variant="secondary">Add price item</b-btn>
					<b-btn v-if="!estimatedPriceInformationValid && validityChecked" class="b-btn" size="sm" disabled variant="danger">Prices invalid</b-btn>
					<b-btn v-else @click="approveNewEstimatedPrices" class="b-btn" size="sm" variant="primary">Approve estimated prices</b-btn>
				</template>
				<template v-else-if="orderCollectionStatus == orderStatusValues.awaitingEstimatedApproval">
					<b-btn @click="editExistingEstimatedPrices" class="b-btn" size="sm" variant="secondary">Edit estimated prices</b-btn>
				</template>
				<template v-else-if="orderCollectionStatus == orderStatusValues.accepted">
					<b-btn @click="requestNewPrices" class="b-btn" size="sm" :disabled="!editable" variant="secondary" :title="!editable ? 'Must accept transportneed first' : null">Add new prices</b-btn>
				</template>
				<template v-else-if="orderCollectionStatus == orderStatusValues.awaitingPrice">
					<b-btn @click="addPriceItem" class="b-btn" size="sm" :disabled="!editable" variant="secondary">Add price item</b-btn>
					<b-btn v-if="!priceInformationValid && validityChecked" class="b-btn" size="sm" disabled variant="danger">Prices invalid</b-btn>
					<b-btn v-else @click="approveNewPrices" class="b-btn" size="sm" :disabled="!editable" variant="primary">Approve new prices</b-btn>
				</template>
				<template v-else>
					<b-btn @click="editExistingPrices" class="b-btn" size="sm" :disabled="!editable" variant="secondary">Edit prices</b-btn>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from '../store';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
	name: 'OrderCollectionPriceList',
	props: ['editable'],
	components: {
		FontAwesomeIcon,
	},
	computed: {
		...mapState({
			priceTypes: 'priceTypes',
			priceDistributions: 'priceDistributions',
			priceAggregations: 'priceAggregations',
			orderStatusValues: 'orderStatusValues',
			orderCollectionData: 'selectedOrderCollectionData',
			validityChecked: 'validityChecked'
		}),
		relatedOrders() {
			return this.orderCollectionData.orders;
		},
		estimatedPriceItems(){
			return this.orderCollectionData.estimatedPriceItems || [];
		},
		priceItems(){
			return this.orderCollectionData.priceItems || [];
		},
		orderCollectionStatus(){
			return this.orderCollectionData.status;
		},
		priceInformationValid(){
			if(this.orderCollectionStatus!==this.orderStatusValues.awaitingPrice)return true;

			var priceItems = this.priceItems;
			for(let item of priceItems) {
				// if(!item.type || !item.description || !item.grossAmount || !item.netAmount){
				// if(!item.type || !item.description || !item.distribution || !item.netAmount){
				if(!item.type || !item.distribution || !item.netAmount){
					return false;
				}
			}
			return true;
		},
		estimatedPriceInformationValid(){
			if(this.orderCollectionStatus!==this.orderStatusValues.awaitingEstimatedPrice)return true;

			var priceItems = this.estimatedPriceItems;
			for(let item of priceItems) {
				// if(!item.type || !item.description || !item.grossAmount || !item.netAmount){
				// if(!item.type || !item.description || !item.distribution || !(item.netAmount || item.basePrice)){
				if(!item.type || !item.distribution || !(item.netAmount || item.basePrice)){
					return false;
				}
			}
			return true;
		},
	},
	watch: {
		priceInformationValid: {
			handler: function(val){
				this.validatePriceItemElements();
				this.$emit('validStateChanged', val);
			},
			immediate: true
		},
		estimatedPriceInformationValid: {
			handler: function(val){
				this.validatePriceItemElements();
				this.$emit('validStateChanged', val);
			},
			immediate: true
		},
		validityChecked: {
			handler: function() {
				if(
					this.orderCollectionStatus===this.orderStatusValues.awaitingPrice ||
					this.orderCollectionStatus===this.orderStatusValues.awaitingEstimatedPrice
				){
					this.validatePriceItemElements();
				}
			}
		}
	},
	methods: {
		...mapMutations([
			'updateSelectedOrderCollectionData',
			'setValidityChecked',
			'toggleHasPriceChanges',
			'addEmptyPriceItemToOrderCollection',
			'removePriceItemFromOrderCollection',
			'spliceEmptyPrices',
			'toggleHasEstimatedPriceChanges',
			'addEmptyEstimatedPriceItemToOrderCollection',
			'removeEstimatedPriceItemFromOrderCollection',
			'spliceEmptyEstimatedPrices',
		]),
		...mapActions([
			'acceptPrices',
			'requestPrices',
			'editPrices',
			'approvePrices',
			'editEstimatedPrices',
			'approveEstimatedPrices',
		]),
		acceptExistingPrices(){
			this.acceptPrices(this.orderCollectionData.id);
		},
		requestNewPrices(){
			this.requestPrices(this.orderCollectionData.id);
		},
		editExistingPrices(){
			this.editPrices(this.orderCollectionData.id);
		},
		approveNewPrices(){
			// console.log(this);
			this.spliceEmptyPrices(this.orderCollectionData.id);
			var priceItems = this.orderCollectionData.priceItems;
			var valid = true;
			this.setValidityChecked(true);
			for(let item of priceItems) {
				// if(!item.type || !item.description || !item.grossAmount || !item.netAmount){
				// if(!item.type || !item.description || !item.distribution || !item.netAmount){
				if(!item.type || !item.distribution || !item.netAmount){
					this.validatePriceItemElements();
					valid = false;
					break;
				}
			}
			if(this.orderCollectionData.priceItems.length && valid){
				this.setValidityChecked(false);
				this.approvePrices(this.orderCollectionData.id);
			}
		},
		validatePriceItemElements(){
			if(!this.validityChecked) return;

			let priceItems = this.orderCollectionData.priceItems;
			for(let item of priceItems) {
				let el = this.$refs[item.id];
				if(!item.type){
					el[0].children[0].classList.add('is-invalid');
				} else {
					el[0].children[0].classList.remove('is-invalid');
				}
				// if(!item.description){
				// 	el[0].children[1].classList.add('is-invalid');
				// } else {
				// 	el[0].children[1].classList.remove('is-invalid');
				// }
				if(!item.distribution){
					el[0].children[2].classList.add('is-invalid');
				} else {
					el[0].children[2].classList.remove('is-invalid');
				}
				if(!item.netAmount){
					el[0].children[4].classList.add('is-invalid');
				} else {
					el[0].children[4].classList.remove('is-invalid');
				}
			}
		},
		addPriceItem() {
			this.addEmptyPriceItemToOrderCollection();
		},
		removePriceItem(priceId) {
			this.removePriceItemFromOrderCollection(priceId);
			if(this.orderCollectionData.hasPriceChanges !== true){
				this.toggleHasPriceChanges({id: this.orderCollectionData.id, state: true});
			}
		},
		onTypeChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderCollectionData.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.type !== value){
				this.$set(priceItem, 'type', value);
				this.$set(priceItem, 'isAccepted', false);
				this.$set(priceItem, 'retainAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				this.updateRelatedOrderPriceValue(priceId, 'type', value);
				if(this.orderCollectionData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onDistributionChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderCollectionData.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.distribution !== value){
				this.$set(priceItem, 'distribution', value);
				this.$set(priceItem, 'isAccepted', false);
				this.$set(priceItem, 'retainAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				this.updateRelatedOrderPriceAmount(priceItem);
				if(this.orderCollectionData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onDescriptionChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderCollectionData.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.description !== value){
				this.$set(priceItem, 'description', value);
				this.$set(priceItem, 'isAccepted', false);
				this.$set(priceItem, 'retainAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				this.updateRelatedOrderPriceValue(priceId, 'description', value);
				if(this.orderCollectionData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onCurrencyChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderCollectionData.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.currency !== value){
				this.$set(priceItem, 'currency', value);
				this.$set(priceItem, 'isAccepted', false);
				this.$set(priceItem, 'retainAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				this.updateRelatedOrderPriceValue(priceId, 'currency', value);
				if(this.orderCollectionData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onGrossChanged(value, priceId) {
			if(value === 'null' || !value) value = null;
			else value = parseFloat(value);
			var priceItem = this.orderCollectionData.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.grossAmount !== value){
				this.$set(priceItem, 'grossAmount', value);
				this.$set(priceItem, 'isAccepted', false);
				this.$set(priceItem, 'retainAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				this.updateRelatedOrderPriceAmount(priceItem);
				if(this.orderCollectionData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onNetChanged(value, priceId) {
			if(value === 'null' || !value) value = null;
			else value = parseFloat(value);
			var priceItem = this.orderCollectionData.priceItems.filter(p => p.id === priceId)[0];
			if(priceItem.netAmount !== value){
				this.$set(priceItem, 'netAmount', value);
				this.$set(priceItem, 'grossAmount', value);
				this.$set(priceItem, 'isAccepted', false);
				this.$set(priceItem, 'retainAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				this.updateRelatedOrderPriceAmount(priceItem);
				if(this.orderCollectionData.hasPriceChanges !== true){
					this.toggleHasPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		editExistingEstimatedPrices(){
			this.editEstimatedPrices(this.orderCollectionData.id);
		},
		approveNewEstimatedPrices(){
			this.spliceEmptyEstimatedPrices(this.orderCollectionData.id);
			var estimatedPriceItems = this.orderCollectionData.estimatedPriceItems;
			var valid = true;
			this.setValidityChecked(true);
			for(let item of estimatedPriceItems) {
				// if(!item.type || !item.description || !item.grossAmount || !item.netAmount){
				// if(!item.type || !item.description || !item.distribution || !(item.netAmount || item.basePrice)){
				if(!item.type || !item.distribution || !(item.netAmount || item.basePrice)){
					this.validateEstimatedPriceItemElements();
					valid = false;
					break;
				}
			}
			if(this.orderCollectionData.estimatedPriceItems.length && valid){
				this.setValidityChecked(false);
				this.approveEstimatedPrices(this.orderCollectionData.id);
			}
		},
		validateEstimatedPriceItemElements(){
			if(!this.validityChecked) return;

			let estimatedPriceItems = this.orderCollectionData.estimatedPriceItems;
			for(let item of estimatedPriceItems) {
				let el = this.$refs[item.id];
				if(!item.type){
					el[0].children[0].classList.add('is-invalid');
				} else {
					el[0].children[0].classList.remove('is-invalid');
				}
				// if(!item.description){
				// 	el[0].children[1].classList.add('is-invalid');
				// } else {
				// 	el[0].children[1].classList.remove('is-invalid');
				// }
				if(!item.distribution){
					el[0].children[2].classList.add('is-invalid');
				} else {
					el[0].children[2].classList.remove('is-invalid');
				}
				if(!item.netAmount && !item.basePrice){
					el[0].children[4].classList.add('is-invalid');
				} else {
					el[0].children[4].classList.remove('is-invalid');
				}
			}
		},
		addEstimatedPriceItem() {
			this.addEmptyEstimatedPriceItemToOrderCollection();
		},
		removeEstimatedPriceItem(priceId) {
			this.removeEstimatedPriceItemFromOrderCollection(priceId);
			if(this.orderCollectionData.hasEstimatedPriceChanges !== true){
				this.toggleHasEstimatedPriceChanges({id: this.orderCollectionData.id, state: true});
			}
		},
		onEstimatedTypeChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderCollectionData.estimatedPriceItems.filter(p => p.id === priceId)[0];
			if(priceItem.type !== value){
				this.$set(priceItem, 'type', value);
				this.$set(priceItem, 'isAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasEstimatedPriceChanges !== true){
					this.toggleHasEstimatedPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onEstimatedDescriptionChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderCollectionData.estimatedPriceItems.filter(p => p.id === priceId)[0];
			if(priceItem.description !== value){
				this.$set(priceItem, 'description', value);
				this.$set(priceItem, 'isAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasEstimatedPriceChanges !== true){
					this.toggleHasEstimatedPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onEstimatedDistributionChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderCollectionData.estimatedPriceItems.filter(p => p.id === priceId)[0];
			if(priceItem.distribution !== value){
				this.$set(priceItem, 'distribution', value);
				this.$set(priceItem, 'isAccepted', false);
				let basePriceUnit = (this.getDistribution(value) || {}).unit;
				if(!basePriceUnit){
					this.$set(priceItem, 'basePrice', null);
				} else {
					this.$set(priceItem, 'basePriceUnit', basePriceUnit);
				}
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasPriceChanges !== true){
					this.toggleHasEstimatedPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onEstimatedCurrencyChanged(value, priceId) {
			if(value === 'null') value = null;
			var priceItem = this.orderCollectionData.estimatedPriceItems.filter(p => p.id === priceId)[0];
			if(priceItem.currency !== value){
				this.$set(priceItem, 'currency', value);
				this.$set(priceItem, 'isAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasEstimatedPriceChanges !== true){
					this.toggleHasEstimatedPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onEstimatedNetChanged(value, priceId) {
			if(value === 'null' || !value) value = null;
			else value = parseFloat(value);
			var priceItem = this.orderCollectionData.estimatedPriceItems.filter(p => p.id === priceId)[0];
			if(priceItem.netAmount !== value){
				this.$set(priceItem, 'netAmount', value);
				this.$set(priceItem, 'grossAmount', value); // Gross removed from gui. Remove if added back
				this.$set(priceItem, 'isAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasEstimatedPriceChanges !== true){
					this.toggleHasEstimatedPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onEstimatedBasePriceChanged(value, priceId) {
			if(value === 'null' || !value) value = null;
			else value = parseFloat(value);
			var priceItem = this.orderCollectionData.estimatedPriceItems.filter(p => p.id === priceId)[0];
			if(priceItem.basePrice !== value){
				this.$set(priceItem, 'basePrice', value);
				this.$set(priceItem, 'isAccepted', false);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasEstimatedPriceChanges !== true){
					this.toggleHasEstimatedPriceChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		updateRelatedOrderPriceAmount(parentPriceItem) {
			var parentId = parentPriceItem.id;
			var basePrice = parentPriceItem.netAmount;
			var distributionType = parentPriceItem.distribution;

			if(!basePrice || !distributionType) return null;

			var distribution = this.getDistribution(distributionType);
			var dividerFunction = distribution.divider;

			for(let order of this.relatedOrders){
				var divider = dividerFunction(order, this.orderCollectionData);
				var amount = basePrice * divider;
				amount = parseFloat(amount.toFixed(2)); // Max 2 decimals
				var priceItem = order.transporter.priceItems.filter(p => p.parentId === parentId)[0];
				this.$set(priceItem, 'netAmount', amount);
				this.$set(priceItem, 'grossAmount', amount);
				this.$set(priceItem, 'isAccepted', false);

				let aggregation = this.getAggregation(distributionType);
				if(aggregation){
					// This means that distribution relates to an aggregation and can be set directly on order price
					this.$set(priceItem, 'basePrice', basePrice);
					this.$set(priceItem, 'basePriceUnit', aggregation.unit);
					this.$set(priceItem, 'aggregation', aggregation.type);
				} else {
					// If changed from distribution that relates, to one that does not
					this.$set(priceItem, 'basePrice', null);
					this.$set(priceItem, 'basePriceUnit', null);
					this.$set(priceItem, 'aggregation', null);
				}
			}
		},
		updateRelatedOrderPriceValue(parentId, key, value) {
			for(var order of this.relatedOrders){
				var priceItem = order.transporter.priceItems.filter(p => p.parentId === parentId)[0];
				this.$set(priceItem, key, value);
				this.$set(priceItem, 'isAccepted', false);
			}
		},
		getTypeDescription(type){
			if(!type) return null;
			var priceType = this.priceTypes.filter(x => x.type === type)[0];
			return priceType.description;
		},
		getDistributionDescription(type){
			if(!type) return null;
			var priceDistribution = this.priceDistributions.filter(x => x.type === type)[0];
			return priceDistribution.description;
		},
		getDistribution(type){
			if(!type) return null;
			var priceDistribution = this.priceDistributions.filter(x => x.type === type)[0];
			return priceDistribution;
		},
		getAggregation(type){
			if(!type) return null;
			var priceAggregation = this.priceAggregations.filter(x => x.type === type)[0];
			return priceAggregation;
		}
	}
};
</script>

<style lang="scss" scoped>
.componentContent {
	display: grid;
	grid-gap: 10px;
	margin: 1rem;
	width: 100%;
	grid-auto-rows: auto 1fr auto;
	grid-template-columns: 1fr;
	grid-template-areas: 'estimated' 'actual' 'buttonContainer';
}
.componentContent.actual {
	grid-template-columns: 1fr;
}
.componentContent.estimated {
	grid-template-columns: 1fr;
}
@media (min-width: 768px) {
	.componentContent {
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: 1fr auto;
		grid-template-areas:
			'estimated actual'
			'buttonContainer buttonContainer';
	}
	.componentContent.actual {
		grid-template-columns: 1fr 2fr;
	}
	.componentContent.estimated {
		grid-template-columns: 2fr 1fr;
	}
}
.componentHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 12px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentContainer {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
	display:flex;
}
.componentContainer.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.priceList {
	margin: 1rem 0;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
}
.priceList li {
	display: inline-block;
	padding: 0.3rem;
	min-width: calc( 100% - 0.6rem);
	text-align: left;
}
.priceList li:not(.new):nth-child(odd) {
	background-color: #f0f0f0;
}
.priceList li span.type,
.priceList li span.description {
	text-align: left;
	padding-left: 0.375rem;
}
.priceList li span {
	text-align: right;
}
.priceList li span svg {
	margin-left: .375rem;
}
.componentContainer.flex-fill {
	flex: 1;
}
.priceItem {
	display: flex;
	justify-content: space-between;
}
.priceItem .close {
	font-size: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: .25rem;
}
.priceItem input,
.priceItem select {
	width: 100%;
}
.priceItem .currency,
.priceItem .distribution,
.priceItem .type {
	padding: 0;
	padding-left: .375rem;
	font-size: 100%;
}
.b-btn {
	margin: 0 5px;
}
.priceItem.editable .input-group {
    display: grid;
    grid-gap: 0px;
    width: 100%;
    grid-template-columns: 2fr 4fr 2.5fr 1fr 1.5fr;
    grid-template-areas: 'type description distribution currency net';
    // grid-template-columns: 2fr 3fr 1fr 1.5fr 1.5fr;
    // grid-template-areas: 'type description currency gross net';
}
.priceItem.estimated .input-group {
    display: grid;
    grid-gap: 0px;
    width: 100%;
    grid-template-columns: 2fr 4fr 2.5fr 1fr 1.5fr;
    grid-template-areas: 'type description distribution currency net';
}
.priceItem:not(.editable) {
    display: grid;
    grid-gap: 0px;
    width: 100%;
    grid-template-columns: 3fr 7fr 7fr 4fr 16px;
    grid-template-areas: 'type description distribution price accepted';
}
.priceItem.estimated:not(.editable) {
    display: grid;
    grid-gap: 0px;
    width: 100%;
    grid-template-columns: 3fr 7fr 7fr 4fr;
    grid-template-areas: 'type description distribution price';
}
</style>


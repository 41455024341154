<template>
	<div class="componentContainer">
		<div class="componentWrapper border">
			<div class="componentHeader">
				Related Orders
			</div>
			<ag-grid-vue
				width="100%"
				class="ag-theme-alpine grid"
				@gridReady="onGridReady"
				:gridOptions="gridOptions"
				:columnDefs="columnDefs"
				:rowData="relatedOrders"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from '../store';
import { AgGridVue } from 'ag-grid-vue';
import moment from 'moment-timezone';
import { negotiateTranslatedText, orderStatusValues, customerLogos } from '@/core';

import DateFilter from '../dateFilters/DateFilter.vue';
import FloatingDateFilter from '../dateFilters/FloatingDateFilter.vue';

export default {
	name: 'ComponentRelatedOrders',
	components: {
		AgGridVue,
	},
	data() {
		return {
			gridOptions: {},
			columnDefs: [],
		};
	},
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData'
		}),
		relatedOrders() {
			return this.orderCollectionData.orders;
		}
	},
	methods: {
		t(key) {
			return negotiateTranslatedText('', key);
		},
		onGridReady() {
		},
		async onRowSelected(e){
			let orderId = e.data.id;
			let link = `/orders/${orderId}`;
			window.open(link, '_blank');
		},
	},
	beforeMount() {
		this.gridOptions = {
			enableCellChangeFlash: true,
			domLayout: 'autoHeight',
			suppressPropertyNamesCheck: true,
			pagination: false,
			context: {
				componentParent: this
			},
			onRowDoubleClicked: this.onRowSelected,
			suppressRowClickSelection: true,
			rowSelection: 'multiple',
			onSelectionChanged: this.onRowMultipleSelectionChanged,
			refreshCells: true,
			getRowNodeId: (data) => {return data.id;},
			dateComponentParams: {dateFormat: 'YYYYMMDDHHmm'},
			rowHeight: 48,
			defaultColDef: {
				editable: false,
				headerClass: 'table-header',
				resizable: true,
				floatingFilter: true,
				filterParams: {
					buttons: ['clear'],
				},
				sortable: true,
				filter: true,
			},
		};
		this.columnDefs = [
			{
				field: 'customerLogo',
				headerName: '',
				width: 100,
				resizable: false,
				valueGetter: (params) => {
					if(!params.data.customerSettings) return;
					return params.data.customerSettings.name;
				},
				cellRenderer: (params) => {
					if(!params.data.customerSettings) return;
					var customerCode = params.data.customerSettings.code;
					if(customerCode) {
						let src = customerLogos[customerCode];
						return `<img data-name="logo" class="order-customer-logo" src="${src}">`;
					}
				},
			},
			{
				field: 'orderNo',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueGetter: (params) => {
					return parseInt(params.data.orderNo);
				},
				cellRenderer: (params) => {
					return `${params.data.orderNo}<br>
					${params.data.transporter.referenceNo || ''}`;
				},
				comparator: (a,b) => +a - +b
			},
			{
				field: 'poReference',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				width: 150,
				valueGetter: (params) => {
					return parseInt(params.data.poReference);
				},
				comparator: (a,b) => +a - +b
			},
			{
				field: 'status',
				width: 150,
				sort: 'asc',
				sortIndex: 0,
				valueGetter: (params) => {
					return params.data.status;
				},
				cellRenderer: (params) => {
					return this.t(`orderStatus/${orderStatusValues(params.data.status)}`);
				}
			},
			{
				field: 'etd',
				cellClass: 'left',
				sort: 'asc',
				sortIndex: 1,
				valueGetter: (params) => {
					var etd = params.data.etd;
					if(etd == null) return null;
					return moment(etd.date, 'YYYYMMDDHHmm');
				},
				cellRenderer: (params) => {
					var etd = params.data.etd;
					return dateRender(etd, 'DD.MM.YY');
				},
				width: 171,
				comparator: dateCompare,
				filterFramework: DateFilter,
				filterParams: {
					dateFormat: 'DD.MM.YY',
					clearButton: true
				},
				floatingFilterComponentFramework: FloatingDateFilter,
				floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			},
			{
				field: 'eta',
				cellClass: 'left',
				valueGetter: (params) => {
					var eta = params.data.eta;
					if(eta == null) return null;
					return moment(eta.date, 'YYYYMMDDHHmm');
				},
				cellRenderer: (params) => {
					var eta = params.data.eta;
					return dateRender(eta, 'DD.MM.YY');
				},
				width: 171,
				comparator: dateCompare,
				filterFramework: DateFilter,
				filterParams: {
					dateFormat: 'DD.MM.YY',
					clearButton: true
				},
				floatingFilterComponentFramework: FloatingDateFilter,
				floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			},
			{
				field: 'atd',
				cellClass: 'left',
				valueGetter: (params) => {
					var atd = params.data.atd;
					if(atd == null) return null;
					return moment(atd.date, 'YYYYMMDDHHmm');
				},
				cellRenderer: (params) => {
					var atd = params.data.atd;
					return dateRender(atd, 'DD.MM.YY');
				},
				width: 171,
				comparator: dateCompare,
				filterFramework: DateFilter,
				filterParams: {
					dateFormat: 'DD.MM.YY',
					clearButton: true
				},
				floatingFilterComponentFramework: FloatingDateFilter,
				floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			},
			{
				field: 'ata',
				cellClass: 'left',
				valueGetter: (params) => {
					var ata = params.data.ata;
					if(ata == null) return null;
					return moment(ata.date, 'YYYYMMDDHHmm');
				},
				cellRenderer: (params) => {
					var ata = params.data.ata;
					return dateRender(ata, 'DD.MM.YY');
				},
				width: 171,
				comparator: dateCompare,
				filterFramework: DateFilter,
				filterParams: {
					dateFormat: 'DD.MM.YY',
					clearButton: true
				},
				floatingFilterComponentFramework: FloatingDateFilter,
				floatingFilterComponentParams: { dateFormat: 'DD.MM.YY' },
			},
			{
				field: 'consignor',
				headerName: 'From',
				cellClass:'left',
				width: 350,
				sort: 'asc',
				sortIndex: 2,
				cellRenderer: (params) => {
					let party = params.data.despatchParty ? params.data.despatchParty : params.data.consignor;
					return addressRender(party);
				},
				valueGetter(params) {
					let party = params.data.despatchParty ? params.data.despatchParty : params.data.consignor;
					return `${party.companyName} ${party.street} ${party.postalPlace}`;
				},
			},
			{
				field: 'consignee',
				headerName: 'To',
				cellClass:'left',
				width: 350,
				cellRenderer: (params) => {
					let party = params.data.deliveryParty ? params.data.deliveryParty : params.data.consignee;
					return addressRender(party);
				},
				valueGetter(params) {
					let party = params.data.deliveryParty ? params.data.deliveryParty : params.data.consignee;
					return `${party.companyName} ${party.street} ${party.postalPlace}`;
				},
			},
			{
				field: 'units',
				headerName: 'Units',
				type: 'numericColumn',
				width: 100,
				valueGetter: (params) => {
					return params.data.freightLines.reduce((sum, f) => sum + f.quantity, 0);
				},
			},
			{
				field: 'weight',
				headerName: 'Weight',
				type: 'numericColumn',
				width: 125,
				valueGetter: (params) => {
					return params.data.freightLines.reduce((sum, f) => sum + f.grossWeight, 0).toFixed(2);
				},
			},
			{
				field: 'incotermsCode',
				cellClass: 'left',
				headerName: 'Incoterms',
				width: 150,
				valueGetter: (params) => {
					if(!params.data.incotermsCode || !params.data.incotermsPlace) return null;
					return `${params.data.incotermsCode} - ${params.data.incotermsPlace}`;
				}
			},
		];
	}
};
function addressRender(party) {
	return `
        <div class="firstAddressLine">${party.companyName}</div>
        <div class="secondAddressLine">${party.street}, ${party.postalPlace}</div>
    `;
}
function dateRender(dateObject, dateFormat) {
	var date = moment(dateObject.date, 'YYYYMMDDHHmm');
	//date.tz(dateObject.timeZone);
	var value = date.format(dateFormat);
	if (dateObject.hasTime) value += date.format(' HH:mm');
	if (!date.isValid()) return null;
	let weekday =  moment(dateObject.date, 'YYYYMMDDHHmm').format('ddd');
	let dow = moment(dateObject.date, 'YYYYMMDDHHmm').isoWeekday() - 1;
	return `
        <div class="dateValue">${value}</div>
        <div class="dateDay" style="--dow:${dow};">${weekday}</div>
    `;
}
function dateCompare(a, b) {
	// return a.dateObject.valueOf() - b.dateObject.valueOf();
	// let first = a.date;
	// let second = b.date;
	// if(first == null) first = 0;
	// if(second == null) second = 0;
	return a.valueOf() - b.valueOf();
}
</script>

<style lang="scss" scoped>
.componentHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	left:0;
	font-size: 12px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 1rem;
}
.componentWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.grid {
    border-radius: 5px;
}
.grid /deep/ .firstAddressLine {
    font-size: 1em;
}
.grid /deep/ .secondAddressLine {
    font-size: 0.75em;
}
.grid /deep/ .dateValue {
    font-size: 1em;
}
.grid /deep/ .dateDay {
    font-size: 0.75em;
	padding-left: calc(15.5px * var(--dow));
}
.grid /deep/ .ag-cell:not(:last-child),
.grid /deep/ .ag-header-cell:not(:last-child),
.grid /deep/ .ag-pinned-left-cols-container .ag-cell,
.grid /deep/ .ag-pinned-left-header .ag-header-cell {
	border-right: 1px solid #e6e6e6 !important;
}
.grid /deep/ .ag-cell.ag-cell-focus,
.grid /deep/ .ag-pinned-left-cols-container .ag-cell.ag-cell-focus {
    border: 1px solid darkgrey !important;
}
.grid /deep/ .ag-cell.left {
    text-align: left;
}
.grid /deep/ .ag-cell.mono {
	font-family: 'Roboto Mono';
}
.ag-theme-bootstrap /deep/ .ag-header-cell {
    color: #9fa8b0;
    font-size: 12px;
}
.ag-cell.left {
    text-align: left;
}
.grid /deep/ .order-customer-logo {
    max-width: 4rem;
    max-height: 2rem;
    width: auto;
    height: auto;
}
</style>


<template>
	<div class="componentContainer">
		<div class="componentWrapper border">
			<div class="componentHeader">
				Transport Summary
			</div>
			<ul class="summaryList flex-fill">
				<li>
					<span>Qty: {{ this.orderQuantity }}{{ this.collectionQuantity }}</span>
				</li>
				<li>
					<span>{{ this.orderWeight }}{{ this.collectionWeight }} kg</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import {mapState} from '../store';
export default {
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData'
		}),
		relatedOrders(){
			return this.orderCollectionData.orders;
		},
		collectionQuantity(){
			return this.orderCollectionData.numberOfPackages ? `/${this.orderCollectionData.numberOfPackages}` : null;
		},
		collectionWeight(){
			return this.orderCollectionData.grossWeight ? `/${this.orderCollectionData.grossWeight.toFixed(2)}` : null;
		},
		orderQuantity(){
			return this.relatedOrders.map(x => x.freightLinesQuantity).reduce((sum, elem) => sum + elem, 0);
		},
		orderWeight(){
			return this.relatedOrders.map(x => x.freightLinesGrossWeight).reduce((sum, elem) => sum + elem, 0).toFixed(2);
		},
	}
};
</script>

<style lang="scss" scoped>
.componentHeader {
	background-color: inherit;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 0.75rem;
	display:flex;
}
.componentWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.componentContent {
	text-align: left;
	margin-bottom:5px;
	line-height: 15px;
	display: flex;
}
.componentContent.bold {
	font-weight: bold;
}
.summaryList {
	margin: 0.75rem;
	padding-left: 0;
	display:flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
}
.summaryList li {
	display: inline-block;
}
</style>

<template>
	<modal
		:visible="show"
		size="lg"
		@close="handleClosing"
		:title="title"
		centered
		class="closingModal"
		:okButton="false"
		:cancelButton="false"
	>
		<b-container>
			<div class="componentWrapper border">
				<b-input-group>
					<div class="componentHeader">
						Comment
					</div>
					<b-form-input
						size="sm"
						title="Comment"
						type="text"
						:value="comment"
						@change="onCommentChanged($event)"
					></b-form-input>
				</b-input-group>
			</div>
			<div class="componentWrapper border">
				<b-input-group>
					<div class="componentHeader">
						Vehicle No.
					</div>
					<b-form-input
						size="sm"
						title="License Plate"
						type="text"
						:value="vehicleLicensePlate"
						@change="onLicensePlateChanged($event)"
					></b-form-input>
				</b-input-group>
			</div>
		</b-container>
		

		<b-container slot="footer" fluid>
			<b-btn slot="modal-cancel" variant="secondary" @click="close">Close</b-btn>
			<b-btn variant="primary" @click="accept" >Accept</b-btn>
		</b-container>
	</modal>
</template>

<script>
import StackableModal from '../../StackableModal.vue';
import {mapState, mapMutations} from '../store';

export default {
	name: 'InitialIformationModal',
	props: ['visible'],
	components: {
		'modal': StackableModal
	},
	data() {
		return {
			show: this.visible,
			title:'Initial information needed',
			accepted: false,
			vehicleLicensePlate: null,
			comment: null,
		};
	},
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData',
		}),
		relatedOrders() {
			return this.orderCollectionData.orders;
		}
	},
	methods: {
		...mapMutations([
			'toggleHasChanges',
			'updateSelectedOrderCollectionData',
		]),
		handleClosing(){
			this.$emit('close', this.accepted);
		},
		close() {
			this.show = false;
		},
		accept() {
			this.accepted = true;
			this.show = false;
		},
		onLicensePlateChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.vehicleLicensePlate !== value){
				this.$set(this.orderCollectionData, 'vehicleLicensePlate', value);
				this.updateRelatedOrderData('vehicleLicensePlate', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onCommentChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.transporterComment !== value){
				this.$set(this.orderCollectionData, 'transporterComment', value);
				this.updateRelatedOrderData('transporterComment', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		updateRelatedOrderData(key, value) {
			for(var order of this.relatedOrders){
				this.$set(order, key, value);
			}
		},
	},

};
</script>

<style lang="scss" scoped>
.closingModal /deep/ .modal-header {
	padding:.5rem;
	padding-bottom:.25rem;
	border-bottom: none;
}
.closingModal /deep/ .modal-header .modal-title{
	font-size:15px;
}
.closingModal /deep/ .modal-header .close:focus{
	outline: none;
}
.closingModal /deep/ .modal-footer {
	border-top: none;
	padding: .5rem 0;
}
.closingModal footer.modal-footer button {
	margin: 0 5px;
}
.closingModal footer.modal-footer button:first-child {
	margin-left: 0;
}
.closingModal footer.modal-footer button:last-child {
	margin-right: 0;
}
.componentHeader {
	background-color: inherit;
	text-align: left;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 1rem;
}
.componentWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
</style>


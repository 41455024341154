<template>
	<div class="componentContainer">
		<div class="componentWrapper border" style="grid-area:estimated;">
			<b-input-group class="datetimepicker-input-group">
				<div class="componentHeader etd">
					<!--
						Etd - Earliest time departure
						Fixed in css
					-->
				</div>
				<date-picker
					size="sm"
					title="Estimated time departure"
					:value="etd"
					:disabled="!editable"
					:invalid="etdInvalid"
					class="datetime-container first"
					dateFormat="DD.MM.YYYY HH:mm"
					@input="onDateChanged($event, 'etd')"
				></date-picker>
				<div class="componentHeader eta">
					<!--
						Eta / Latest time arrival
						Fixed in css
					-->
				</div>
				<date-picker
					size="sm"
					title="Estimated time arrival"
					:value="eta"
					:disabled="!editable"
					:invalid="etaInvalid"
					class="datetime-container last"
					dateFormat="DD.MM.YYYY HH:mm"
					@input="onDateChanged($event, 'eta')"
				></date-picker>
			</b-input-group>
		</div>
		<div class="componentWrapper border" style="grid-area:actual;">
			<b-input-group class="datetimepicker-input-group">
				<div class="componentHeader atd">
					<!--
						Atd / Actual time departure
						Fixed in css
					-->
				</div>
				<date-picker
					size="sm"
					title="Actual time departure"
					:value="atd"
					:disabled="!editable || atdSetByConfirm"
					:invalid="atdInvalid"
					class="datetime-container first"
					dateFormat="DD.MM.YYYY HH:mm"
					@input="onDateChanged($event, 'atd')"
				></date-picker>
				<div class="componentHeader ata">
					<!--
						Ata / Actual time arrival
						Fixed in css
					-->
				</div>
				<date-picker
					size="sm"
					title="Actual time arrival"
					:value="ata"
					:disabled="!editable || ataSetByConfirm"
					:invalid="ataInvalid"
					class="datetime-container last"
					dateFormat="DD.MM.YYYY HH:mm"
					@input="onDateChanged($event, 'ata')"
				></date-picker>
			</b-input-group>
		</div>
		<div class="componentWrapper border" style="grid-area:awb;">
			<div class="componentHeader">
				Air Waybill
			</div>
			<b-form-input
				size="sm"
				title="Air Waybill number"
				type="text"
				:disabled="!editable"
				:value="awbReference"
				@change="onAwbReferenceChanged($event)"
				:state="validityChecked ? awbReferenceValid : null"
			></b-form-input>
			<b-form-invalid-feedback :state="validityChecked ? awbReferenceValid : null">
				Format of air waybill must be <em>xxx-xxxxxxxx</em>
			</b-form-invalid-feedback>
		</div>
		<div class="componentWrapper border" style="grid-area:customs;">
			<div class="componentHeader">
				Customs crossing point
			</div>
			<b-input-group>
				<b-form-select
					class="modalSelect"
					:class="{ 'font-italic': !customsPointSelected }"
					size="sm"
					title="Customs border crossing point"
					v-model="customsPointSelected"
					@change="onCustomsCrossingChanged($event)"
					:disabled="!editable"
				>
					<option class="font-italic" :value="null"></option>
					<option
						v-for="point in customsPoints"
						style="font-style:normal;"
						class="customs-point"
						:key="point.tollst"
						:value="point.tollst"
						v-text="point.tollsted"
						:disabled="point.disabled"
					/>
				</b-form-select>
			</b-input-group>
		</div>
		<div class="componentWrapper border" style="grid-area:vehicle;">
			<b-input-group class="double-header">
				<div class="componentHeader">
					Vehicle No.
				</div>
				<b-form-input
					size="sm"
					title="License Plate"
					type="text"
					:value="vehicleLicensePlate"
					@change="onLicensePlateChanged($event)"
					:state="validityChecked ? vehicleLicenseValid : null"
					:disabled="!editable || disableVehicle"
				></b-form-input>
				<div class="componentHeader">
					Vehicle nationality
				</div>
				<b-form-select
					class="modalSelect"
					:class="{ 'font-italic': !vehicleNationalitySelected}"
					size="sm"
					title="Vehicle Nationality"
					v-model="vehicleNationalitySelected"
					@change="onVehicleNationalityChanged($event)"
					:state="validityChecked ? vehicleNationalityValid : null"
					:disabled="!editable || disableVehicle"
				>
					<option class="font-italic" :value="null"></option>
					<option
						v-for="(nation, index) in nationality"
						style="font-style:normal;"
						:key="index"
						:value="nation.value"
						v-text="nation.text"
					/>
				</b-form-select>
			</b-input-group>
			<b-form-invalid-feedback :state="validityChecked ? vehicleFeedbackState : null">
				Both values must be set
			</b-form-invalid-feedback>
		</div>
		<div class="componentWrapper border" style="grid-area:driver;">
			<b-input-group class="double-header">
				<div class="componentHeader">
					Vehicle type
				</div>
				<b-form-input
					size="sm"
					title="Vehicle type"
					type="text"
					:disabled="!editable || disableVehicle"
					:value="vehicleType"
					@change="onVehicleTypeChanged($event)"
				></b-form-input>
				<div class="componentHeader">
					Drivers name
				</div>
				<b-form-input
					size="sm"
					title="Drivers name"
					type="text"
					:disabled="!editable || disableVehicle"
					:value="vehicleDriverName"
					@change="onVehicleDriverNameChanged($event)"
				></b-form-input>
			</b-input-group>
		</div>
		<div class="componentWrapper border" style="grid-area:flight;">
			<b-input-group class="double-header" v-if="!disableFlight && disableVehicle">
				<div class="componentHeader">
					Departing flight
				</div>
				<b-form-input
					size="sm"
					title="Departing flight"
					type="text"
					:value="departingFlight"
					@change="onDepartingFlightChanged($event)"
					:state="validityChecked ? departingFlightValid : null"
					:disabled="!editable || disableFlight"
				></b-form-input>
				<div class="componentHeader">
					Arriving flight
				</div>
				<b-form-input
					size="sm"
					title="Arriving flight"
					type="text"
					:value="arrivingFlight"
					@change="onArrivingFlightChanged($event)"
					:state="validityChecked ? arrivingFlightValid : null"
					:disabled="!editable || disableFlight"
				></b-form-input>
			</b-input-group>
			<b-input-group class="double-header" v-else-if="!disableVehicle && disableFlight">
				<div class="componentHeader">
					Departing vehicle
				</div>
				<b-form-input
					size="sm"
					title="Departing vehicle"
					type="text"
					:value="departingVehicle"
					@change="onDepartingVehicleChanged($event)"
					:state="validityChecked ? departingVehicleValid : null"
					:disabled="!editable || disableVehicle"
				></b-form-input>
				<div class="componentHeader">
					Arriving vehicle
				</div>
				<b-form-input
					size="sm"
					title="Arriving vehicle"
					type="text"
					:value="arrivingVehicle"
					@change="onArrivingVehicleChanged($event)"
					:state="validityChecked ? arrivingVehicleValid : null"
					:disabled="!editable || disableVehicle"
				></b-form-input>
			</b-input-group>
			<b-input-group class="double-header" v-else>
				<div class="componentHeader">Departing</div>
				<b-form-input size="sm" title="Departing" type="text" :disabled="true"></b-form-input>
				<div class="componentHeader">Arriving</div>
				<b-form-input size="sm" title="Arriving" type="text" :disabled="true"></b-form-input>
			</b-input-group>
			<b-form-invalid-feedback :state="validityChecked ? pickupAndDeliveryValid : null">
				Both values must be set
			</b-form-invalid-feedback>
		</div>
		<div class="componentWrapper border" style="grid-area:terminalInfo;">
			<div class="componentHeader">
				Terminal information
			</div>
			<b-form-input
				size="sm"
				title="Terminal information"
				type="text"
				:disabled="!editable || disableFlight"
				:value="terminalInformation"
				@change="onTerminalInformationChanged($event)"
			></b-form-input>
		</div>
	</div>
</template>

<script>
import DatePicker from '../dateFilters/DatePicker.vue';
import moment from 'moment';
import { mapState, mapMutations } from '../store';

export default {
	name: 'OrderCollectionDelivery',
	props: ['editable'],
	components: {
		DatePicker
	},
	data() {
		return {
			customsPointSelected: null,
			vehicleNationalitySelected: null,
		};
	},
	computed: {
		...mapState({
			orderCollectionData: 'selectedOrderCollectionData',
			customsPoints: 'customsPoints',
			nationality: 'nationality',
			validityChecked: 'validityChecked',
		}),
		relatedOrders() {
			return this.orderCollectionData.orders;
		},
		atd() {
			if(this.orderCollectionData.atd){
				return moment(this.orderCollectionData.atd.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
			}
			return null;
		},
		ata() {
			if(this.orderCollectionData.ata){
				return moment(this.orderCollectionData.ata.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
			}
			return null;
		},
		etd() {
			if(this.orderCollectionData.etd){
				return moment(this.orderCollectionData.etd.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
			}
			return null;
		},
		eta() {
			if(this.orderCollectionData.eta){
				return moment(this.orderCollectionData.eta.date, 'YYYYMMDDHHmm').format('DD.MM.YYYY HH:mm');
			}
			return null;
		},
		atdSetByConfirm() {
			return this.orderCollectionData.atd.setByConfirm;
		},
		ataSetByConfirm() {
			return this.orderCollectionData.ata.setByConfirm;
		},
		awbReference() {
			return this.orderCollectionData.awbReference;
		},
		crossingPoint() {
			return this.orderCollectionData.customsCrossingPoint;
		},
		vehicleLicensePlate() {
			return this.orderCollectionData.vehicleLicensePlate;
		},
		vehicleNationality() {
			return this.orderCollectionData.vehicleNationality || null;
		},
		vehicleDriverName() {
			return this.orderCollectionData.vehicleDriverName;
		},
		vehicleType() {
			return this.orderCollectionData.vehicleType;
		},
		departingVehicle() {
			return this.orderCollectionData.departingVehicle;
		},
		arrivingVehicle() {
			return this.orderCollectionData.arrivingVehicle;
		},
		departingFlight() {
			return this.orderCollectionData.departingFlight;
		},
		arrivingFlight() {
			return this.orderCollectionData.arrivingFlight;
		},
		terminalInformation() {
			return this.orderCollectionData.terminalInformation;
		},
		ataInvalid(){
			if(this.orderCollectionData.ata && this.orderCollectionData.ata.date !== null){
				let long = 'YYYYMMDDHHmm';
				let atd = moment(this.orderCollectionData.atd.date, 'YYYYMMDDHHmm').isValid();
				let ataLong = moment(this.orderCollectionData.ata.date, long);
				if(
					!(atd ? ataLong.isSameOrAfter(moment(this.orderCollectionData.atd.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		atdInvalid(){
			if(this.orderCollectionData.atd && this.orderCollectionData.atd.date !== null){
				let long = 'YYYYMMDDHHmm';
				let ata = moment(this.orderCollectionData.ata.date, 'YYYYMMDDHHmm').isValid();
				let atdLong = moment(this.orderCollectionData.atd.date, long);
				if(
					!(ata ? atdLong.isSameOrBefore(moment(this.orderCollectionData.ata.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		etaInvalid(){
			if(this.orderCollectionData.eta && this.orderCollectionData.eta.date !== null){
				let long = 'YYYYMMDDHHmm';
				let etd = moment(this.orderCollectionData.etd.date, 'YYYYMMDDHHmm').isValid();
				let etaLong = moment(this.orderCollectionData.eta.date, long);
				if(
					!(etd ? etaLong.isSameOrAfter(moment(this.orderCollectionData.etd.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		etdInvalid(){
			if(this.orderCollectionData.etd && this.orderCollectionData.etd.date !== null){
				let long = 'YYYYMMDDHHmm';
				let eta = moment(this.orderCollectionData.eta.date, 'YYYYMMDDHHmm').isValid();
				let etdLong = moment(this.orderCollectionData.etd.date, long);
				if(
					!(eta ? etdLong.isSameOrBefore(moment(this.orderCollectionData.eta.date, long)) : true)
				){
					return true;
				}
			}
			return false;
		},
		vehicleLicenseValid(){
			if(
				!this.orderCollectionData.vehicleLicensePlate &&
				this.orderCollectionData.vehicleNationality
			){
				return false;
			}
			return null;
		},
		vehicleNationalityValid(){
			if(
				this.orderCollectionData.vehicleLicensePlate &&
				!this.orderCollectionData.vehicleNationality
			){
				return false;
			}
			return null;
		},
		vehicleFeedbackState(){
			if(
				(this.orderCollectionData.vehicleLicensePlate && !this.orderCollectionData.vehicleNationality) ||
				(!this.orderCollectionData.vehicleLicensePlate && this.orderCollectionData.vehicleNationality)
			){
				return false;
			}
			return null;
		},
		awbReferenceValid(){
			if(
				this.orderCollectionData.awbReference &&
				!(this.orderCollectionData.awbReference).match('([0-9]{3})-([0-9]{8})')
			){
				return false;
			}
			return null;
		},
		departingFlightValid(){
			if(
				!this.orderCollectionData.departingFlight &&
				this.orderCollectionData.arrivingFlight
			){
				return false;
			}
			return null;
		},
		arrivingFlightValid(){
			if(
				this.orderCollectionData.departingFlight &&
				!this.orderCollectionData.arrivingFlight
			){
				return false;
			}
			return null;
		},
		flightFeedbackState(){
			if(
				(this.orderCollectionData.departingFlight && !this.orderCollectionData.arrivingFlight) ||
				(!this.orderCollectionData.departingFlight && this.orderCollectionData.arrivingFlight)
			){
				return false;
			}
			return null;
		},
		departingVehicleValid(){
			if(
				!this.orderCollectionData.departingVehicle &&
				this.orderCollectionData.arrivingVehicle
			){
				return false;
			}
			return null;
		},
		arrivingVehicleValid(){
			if(
				this.orderCollectionData.departingVehicle &&
				!this.orderCollectionData.arrivingVehicle
			){
				return false;
			}
			return null;
		},
		pickupAndDeliveryValid(){
			if(
				((this.orderCollectionData.departingFlight && !this.orderCollectionData.arrivingFlight) || (!this.orderCollectionData.departingFlight && this.orderCollectionData.arrivingFlight)) ||
				((this.orderCollectionData.departingVehicle && !this.orderCollectionData.arrivingVehicle) || (!this.orderCollectionData.departingVehicle && this.orderCollectionData.arrivingVehicle))
			) {
				return false;
			}
			return null;
		},
		deliveryInformationValid(){
			var valid = (
				this.vehicleFeedbackState === null &&
				this.flightFeedbackState === null &&
				this.awbReferenceValid === null &&
				!this.etdInvalid && !this.etaInvalid
				&& !this.atdInvalid && !this.ataInvalid
			);
			return valid;
		},
		disableVehicle(){
			if(
				this.orderCollectionData.departingFlight ||
				this.orderCollectionData.arrivingFlight ||
				this.orderCollectionData.terminalInformation ||
				this.orderCollectionData.departingTerminal ||
				this.orderCollectionData.to.iataCode
			) {
				return true;
			}
			return false;
		},
		disableFlight(){
			if(
				this.orderCollectionData.departingVehicle ||
				this.orderCollectionData.arrivingVehicle ||
				this.orderCollectionData.vehicleLicensePlate ||
				this.orderCollectionData.vehicleNationality ||
				this.orderCollectionData.vehicleDriverName ||
				this.orderCollectionData.vehicleType
			) {
				return true;
			}
			return false;
		},
	},
	watch: {
		deliveryInformationValid: {
			handler: function(val){
				this.$emit('validStateChanged', val);
			},
			immediate: true
		},
	},
	methods: {
		...mapMutations([
			'toggleHasChanges',
			'updateSelectedOrderCollectionData',
		]),
		onDateChanged(value, inputType){
			// console.log(moment(moment(value, 'DD.MM.YYYY HH:mm').format('YYYYMMDDHHmm')));
			let dateValid = moment(value, 'DD.MM.YYYY HH:mm').isValid();
			let setByConfirm = this.orderCollectionData[inputType].setByConfirm;
			let newDateObj = {
				date: dateValid ? moment(value, 'DD.MM.YYYY HH:mm').format('YYYYMMDDHHmm') : null,
				hasTime: dateValid,
				timeZone: 'Europe/Oslo'
			};
			// console.log(parseInt(this.orderCollectionData[inputType].date) !== parseInt(newDateObj.date), moment().format('HH:mm:ss'));
			if(parseInt(this.orderCollectionData[inputType].date) !== parseInt(newDateObj.date) && !setByConfirm){
				this.$set(this.orderCollectionData, inputType, newDateObj);
				this.updateRelatedOrderData(inputType, newDateObj);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onAwbReferenceChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.awbReference !== value){
				this.$set(this.orderCollectionData, 'awbReference', value);
				this.updateRelatedOrderData('awbReference', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onCustomsCrossingChanged(value){
			if(value === 'null') value = null;
			if(this.orderCollectionData.customsCrossingPoint !== value){
				this.$set(this.orderCollectionData, 'customsCrossingPoint', value);
				this.updateRelatedOrderData('customsCrossingPoint', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onLicensePlateChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.vehicleLicensePlate !== value){
				this.$set(this.orderCollectionData, 'vehicleLicensePlate', value);
				this.updateRelatedOrderData('vehicleLicensePlate', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onVehicleNationalityChanged(value){
			if(value === 'null') value = null;
			if(this.orderCollectionData.vehicleNationality !== value){
				this.$set(this.orderCollectionData, 'vehicleNationality', value);
				this.updateRelatedOrderData('vehicleNationality', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onVehicleDriverNameChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.vehicleDriverName !== value){
				this.$set(this.orderCollectionData, 'vehicleDriverName', value);
				this.updateRelatedOrderData('vehicleDriverName', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onVehicleTypeChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.vehicleType !== value){
				this.$set(this.orderCollectionData, 'vehicleType', value);
				this.updateRelatedOrderData('vehicleType', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onDepartingVehicleChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.departingVehicle !== value){
				this.$set(this.orderCollectionData, 'departingVehicle', value);
				this.updateRelatedOrderData('departingVehicle', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onArrivingVehicleChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.arrivingVehicle !== value){
				this.$set(this.orderCollectionData, 'arrivingVehicle', value);
				this.updateRelatedOrderData('arrivingVehicle', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onDepartingFlightChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.departingFlight !== value){
				this.$set(this.orderCollectionData, 'departingFlight', value);
				this.updateRelatedOrderData('departingFlight', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onArrivingFlightChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.arrivingFlight !== value){
				this.$set(this.orderCollectionData, 'arrivingFlight', value);
				this.updateRelatedOrderData('arrivingFlight', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		onTerminalInformationChanged(value){
			if(value === '') value = null;
			if(this.orderCollectionData.terminalInformation !== value){
				this.$set(this.orderCollectionData, 'terminalInformation', value);
				this.updateRelatedOrderData('terminalInformation', value);
				this.updateSelectedOrderCollectionData(this.orderCollectionData);
				if(this.orderCollectionData.hasChanges !== true){
					this.toggleHasChanges({id: this.orderCollectionData.id, state: true});
				}
			}
		},
		updateRelatedOrderData(key, value) {
			for(var order of this.relatedOrders){
				this.$set(order, key, value);
			}
		},
	},
	beforeMount() {
		this.customsPointSelected = this.orderCollectionData.customsCrossingPoint;
		this.vehicleNationalitySelected = this.vehicleNationality;
	}
};
</script>

<style lang="scss" scoped>
.componentContainer {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'estimated' 'actual' 'awb' 'customs' 'vehicle' 'driver' 'flight' 'terminalInfo';
}
@media (min-width: 768px) {
	.componentContainer {
		align-content: space-between;
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: minmax(min-content, max-content);
		grid-template-areas:
			'estimated actual'
			'awb customs'
			'vehicle driver'
			'flight terminalInfo';
	}
}
.componentHeader {
	background-color: inherit;
	text-align: left;
	margin-top: -1.5em;
	position: absolute;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}
.componentWrapper {
	background-color: inherit;
	margin-top: 1.5rem;
	position: relative;
	font-size: 1rem;
}
.componentWrapper.border {
	border: 2px #b2b2b2 solid !important;
	border-radius: 5px;
}
.modalSelect {
	padding-top: 0;
	padding-bottom: 0;
}

.invalid-feedback {
	margin-top: 0.25rem;
	position: absolute;
}

.datetimepicker-input-group > .datetime-container {
    width: 50%;
}
.datetimepicker-input-group .datetime-container.first /deep/ input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.datetimepicker-input-group .datetime-container.last /deep/ input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.datetimepicker-input-group > .componentHeader {
    left: 0;
}
.datetimepicker-input-group > .componentHeader ~ .componentHeader {
    left: 50%;
}
.double-header > .componentHeader {
    left: 0;
}
.double-header > .componentHeader ~ .componentHeader {
    left: 50%;
}

.componentHeader.etd:before {
	content: "Etd";
}
.componentHeader.eta:before {
	content: "Eta";
}
.componentHeader.atd:before {
	content: "Atd";
}
.componentHeader.ata:before {
	content: "Ata";
}
@media (min-width: 768px){
	.componentHeader.etd:before {
		content: "Earliest time departure";
	}
	.componentHeader.eta:before {
		content: "Latest time arrival";
	}
	.componentHeader.atd:before {
		content: "Actual time departure";
	}
	.componentHeader.ata:before {
		content: "Actual time arrival";
	}
}
option.customs-point:disabled {
	display: none;
	pointer-events: none;
}
</style>
